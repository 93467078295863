export interface SerializedPoint {
  x: number;
  y: number;
  originalEvent?:  MouseEvent;
}

export class Point implements SerializedPoint {
  x: number;
  y: number;

  constructor(x?: number | null, y?: number | null) {
    this.x = x ?? 0;
    this.y = y ?? 0;
  }

  public add(point: Point): Point {
    return new Point(this.x + point.x, this.y + point.y);
  }

  public distanceTo(point: Point): number {
    const a = this.x - point.x;
    const b = this.y - point.y;

    return Math.sqrt(a * a + b * b);
  }

  public serialize = (): SerializedPoint => ({
    x: this.x,
    y: this.y
  });

  public static serialize = (point: Point) => point.serialize();
}
