import { Injectable, Type } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { BaseWidgetComponent } from '../components/strategy-grid/base-widget/base-widget.component';

export namespace ChatService {
  export type ChatResponse = {
    output?: string;
    steps?: string[];
    error?: string;
    model?: string;
  };

  export type HistoryResponse = {
    title: string;
    messages: ChatMessage[];
  };

  export type ChatSession = {
    title: string;
    session: string;
    mtime: number;
  };

  export type ListResponse = ChatSession[];

  export type RemoveResponse =
    | {
        success: true;
        error: undefined;
      }
    | {
        success: false;
        error: string;
      };

  export type ModelsResponse = {
    revisors: AvailableModel[];
    hasAllModels: boolean;
  };

  export type NewUUIDResponse = {
    uuid: string;
  };

  export type AvailableModel = {
    model: `${string}:${string}`;
    icon: 'deepseek' | 'ollama' | 'qwen' | 'mistral';
    name: string;
    desc: string;
  };

  export type ChatMessage = {
    isSelf: boolean;
    content: string;
    isError?: boolean;
    title?: string;
    model?: string;
    isCollapsed?: boolean;
    lineBreak?: boolean;
    isAnchor?: boolean;
    isFinal?: boolean;
    isHint?: boolean;
    timestamp?: string;
    componentType?: Type<BaseWidgetComponent>;
  };

}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    timeout: 600000
  };

  constructor(private http: HttpClient) {
    (<any>window).$chat = this;
  }

  public chat = (team: string, username: string, uuid: string, msg: string, project: string, model?: string) =>
    this.http.post<ChatService.ChatResponse>(`${ConfigService.chatUrl}/${team}/${username}/${uuid}`, { msg, project, model });

  public chatAsync = async (team: string, username: string, uuid: string, msg: string, project: string, model?: string) =>
    lastValueFrom(this.chat(team, username, uuid, msg, project, model));

  public history = (team: string, username: string, uuid: string) =>
    this.http.get<ChatService.HistoryResponse>(`${ConfigService.chatUrl}/${team}/${username}/${uuid}`);

  public historyAsync = async (team: string, username: string, uuid: string) => lastValueFrom(this.history(team, username, uuid));

  public slash = (team: string, username: string, uuid: string, command: string, args: any) =>
    this.http.post<ChatService.ChatResponse>(`${ConfigService.chatUrl}/${team}/${username}/${uuid}/slash/${command}`, args, this._httpOptions );

  public slashAsync = async (team: string, username: string, uuid: string, command: string, args: any) =>
    lastValueFrom(this.slash(team, username, uuid, command, args));

  public remove = (team: string, username: string, uuid: string) =>
    this.http.delete<ChatService.RemoveResponse>(`${ConfigService.chatUrl}/${team}/${username}/${uuid}`);

  public removeAsync = async (team: string, username: string, uuid: string) => lastValueFrom(this.remove(team, username, uuid));

  public models = () => this.http.get<ChatService.ModelsResponse>(`${ConfigService.chatUrl}/models`);

  public modelsAsync = async () => lastValueFrom(this.models());

  public getNewUUID = () => this.http.get<ChatService.NewUUIDResponse>(`${ConfigService.chatUrl}/new_uuid`);

  public getNewUUIDAsync = async () => lastValueFrom(this.getNewUUID());

  public rename = (team: string, username: string, uuid: string, title: string) =>
    this.http.put<{ error?: string }>(`${ConfigService.chatUrl}/${team}/${username}/${uuid}`, { title });

  public renameAsync = async (team: string, username: string, uuid: string, title: string) =>
    lastValueFrom(this.rename(team, username, uuid, title));

  public list = (team: string, username: string) =>
    this.http.get<ChatService.ListResponse>(`${ConfigService.chatUrl}/${team}/${username}/list`);

  public listAsync = async (team: string, username: string) => lastValueFrom(this.list(team, username));

  public getModelData(
    team: string,
    username: string,
    uuid: string,
    model: string,
    interval: string,
    from: number,
    to: number,
    ensembleMethod: string,
    strats: string[] = []
  ) {
    let url = `${ConfigService.chatUrl}/${team}/${username}/${uuid}/model_history?model_name=${model}&interval=${interval}&from=${from}&to=${to}&ensemble_method=${ensembleMethod}`;
    if (strats && strats.length > 0) {
      url += `&strats=${strats.join(',')}`;
    }
    return this.http.get(url, this._httpOptions);
  }

  getCachedAnalysis = (team: string, username: string, uuid: string, ticker: string) =>
    this.http.get(`${ConfigService.chatUrl}/${team}/${username}/${uuid}/cache/analysis/${ticker}`);
}
