import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounce } from 'src/app/directives/debounce.decorator';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrls: ['./chat-screen.component.scss']
})
export class ChatScreenComponent {
  @ViewChild('content', { static: true }) private _content: ElementRef<HTMLDivElement>;

  @Input() messages: ChatService.ChatMessage[] = [];
  @Input() loading = false;
  @Input() embed = false;

  constructor(private _snackBar: MatSnackBar) {}

  @debounce(100)
  public scrollToTop(): void {
    this._content.nativeElement.scrollTop = 0;
  }

  @debounce(100)
  public scrollToBottom(): void {
    this._content.nativeElement.scrollTop = this._content.nativeElement.scrollHeight;
  }

  @debounce(100)
  public scrollToAnchor(anchorName: string): void {
    const anchor = this._content.nativeElement.querySelector(`a[name="${anchorName}"]`);
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  public copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this._snackBar.open('Copied to clipboard!', 'OK', { duration: 2000 });
    }).catch(err => {
      console.error('Failed to copy text:', err);
    });
  }

}
