import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { GpusService } from 'src/app/services/gpus.service';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';
import { DefinitionsService } from 'src/app/services/definitions.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Meta } from 'src/app/models/meta';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { Asset } from 'src/app/models/asset';
import { AssetsService } from 'src/app/services/assets.service';
import { SerializedWorkflow, WorkflowComponent, Workflow } from 'src/app/models/workflow';
import { WorkflowDrawerEvent } from './workflow-canvas/workflow-canvas.component';
import { Node, NodeParameter } from 'src/app/models/workflow-node';
import { WorkflowService } from 'src/app/services/workflow.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TeamsService } from 'src/app/services/teams.service';
import { MenuService } from 'src/app/services/menu.service';
import { ShallowDefinition } from 'src/app/models/definition';
import { debounce } from 'src/app/directives/debounce.decorator';
import { SocketService } from 'src/app/services/socket.service';
import { InfoSnackbarComponent } from '../../info-snackbar/info-snackbar.component';
import { WidgetService } from 'src/app/services/widget.service';
import { VaultItem } from 'src/app/models/vault-item';
import { VaultService } from 'src/app/services/vault.service';

export type WorkflowDrawerMode = 'SETTINGS' | 'ADDCOMPONENT' | 'EDITCOMPONENT' | 'MULTI_EDIT';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ WorkflowService ]
})
export class DefinitionComponent implements OnInit, OnDestroy {
  public editorOptions = {
    theme: 'vs-dark',
    language: 'text',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    horizontal: 'visible',
    minimap: {
      enabled: false
    }
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private gpuService: GpusService,
    private dataService: DataService,
    private experimentsService: ExperimentsService,
    private definitionsService: DefinitionsService,
    private assetsService: AssetsService,
    private workflowService: WorkflowService,
    private teamsService: TeamsService,
    private _socketService: SocketService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private menuService: MenuService,
    private vaultService: VaultService
  ) {}

  public definitionForm = new FormGroup({
    name: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
    tags: new FormControl<any>([]),
    assets: new FormControl<any>('', [Validators.required]),
    transfer: new FormControl<any>(''),
    device: new FormControl<any>('', [Validators.required])
  });
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @ViewChild('featureInput') featureInput: ElementRef<HTMLInputElement>;
  @ViewChild('svgCanvas') svgCanvas: ElementRef<HTMLIFrameElement>;
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @ViewChild(DynamicFormComponent) componentForm: DynamicFormComponent;

  @Input() isWidget: string;
  @Input() parentId: string;
  @Input() teamName: string;
  @Input() rdsName: string;

  @Output() closeClick = new EventEmitter<{ parentId: string }>();
  @Output() assetClick = new EventEmitter<{ parentId: string; assetName: string }>();
  @Output() viewExpermentsClick = new EventEmitter<{ parentId: string; }>();
  @Output() onEditFile = new EventEmitter<{ path: any }>();
  @Output() onCompile = new EventEmitter<{ parentId: string; rdsName: string }>();

  public schemas: any = [];
  public isLoading: boolean = true;
  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' = 'COMPLETE';
  public tags: Set<string>;
  public devices: ('cpu' | `gpu${number}`)[] = ['cpu'];
  public allGPUS = [];
  public availableModels: any = [];
  public model: any = null;
  public mode: WorkflowDrawerMode = undefined;
  private workflow: Workflow = {
    id: '',
    name: '',
    description: '',
    assets: [],
    transfer: null,
    device: 'cpu',
    tags: [],
    nodes: [],
    connections: []
  };
  private availableComponents: WorkflowComponent[] = [];
  public selectedComponent: any;
  public meta: Meta = null;
  public dynamicFormData: any;
  private componentParameters: NodeParameter[] = [];
  private _subscriptions: any[] = [];
  public get canvasUrl(): string {
    return ConfigService.canvasUrl || '';
  }
  public compileOutput: string = null;
  public assets: any[] = [];
  public launched: boolean = false;
  public formDirty: boolean = false;

  private _socketSub: any;
  private _sessionName: string;

  ngOnInit(): void {
    (<any>window).definition = this;
    this.menuService.delayedSetActive('definitions');
    this.subscribeToWorkflow();
    this._socketService.joinRoom('definition');
    this._subscriptions.push(
      this._socketService.subscribeToRoomEvents('definition', (message: any) => {
        if ('definition' in message && message.definition.toLowerCase() === this.rdsName.toLocaleLowerCase()) {
          this.load();
        }
      })
    );

    if (this.isWidget) {
      this.teamsService.getConfigAsync(this.teamName).then((config) => {
        if (this.rdsName === 'add') {
          this.initNew();
        } else {
          this.load();
        }
      });
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.teamsService.getConfigAsync(this.teamName).then((config) => {
          if (params.rds === 'add') {
            this.initNew();
          } else {
            this.rdsName = params.rds;
            this.load();
          }
        });
      });
    }
  }

  async initNew() {
    this.definitionsService
      .initAsync(this.teamName)
      .then((definition: ShallowDefinition) => {
        if (this.isWidget) {
          this.rdsName = definition.name;
          this.load();
        } else {
          this.router.navigate(['/', this.teamName, 'model', 'definition', definition.name]);
        }
      })
      .catch(console.error);
  }

  subscribeToWorkflow() {
    this._subscriptions.push(
      this.workflowService.workflow$.subscribe((wf) => {
        if (this.workflow.id === '' || this.workflow.id === wf.id) {
          this.workflow = wf;
          if (this.drawer.opened && wf.selectedConnection) this.drawer.close();
        }
      })
    );
    this._subscriptions.push(this.workflowService.dirty$.subscribe(isDirty => {
      if (isDirty) {
        this.formDirty = true;
      }
    }));

  }

  ngOnDestroy(): void {
    this._socketService.leaveRoom('definition');
    if (this._socketSub) this._socketSub.unsubscribe();
    this._subscriptions.forEach((x) => x.unsubscribe());
  }

  $saveWorkflow = (wf: Workflow) => {
    if (!wf.id || this.workflow.id !== wf.id) {
      console.warn('workflow ids do not match', this.workflow.id, wf.id);
      return;
    }
    this.workflow = wf;
    this.updateWorkflow(true);
  };

  $updateDrawer = ($ev: WorkflowDrawerEvent) => {
    const { id, mode } = $ev;
    if (!id || this.workflow.id !== id) {
      console.warn('workflow ids do not match', this.workflow.id, id);
      return;
    }

    const setMode = async () => {
      if (mode) {
        if (this.mode !== mode) this.mode = mode;
        await new Promise((resolve) => setTimeout(resolve, 50));
        if (!this.drawer.opened) await this.drawer.open();
      } else {
        if (this.drawer.opened) await this.drawer.close();
        if (this.mode !== mode) this.mode = mode;
      }
    };

    if (mode) {
      if (mode == 'ADDCOMPONENT') this.prepAddComponent();
      else if (mode == 'EDITCOMPONENT') this.prepEditComponent($ev.component);
    }
    setMode();
  };

  $deleteWorkflow = (id: string) => {
    if (!id || this.workflow.id !== id) {
      console.warn('workflow ids do not match', this.workflow.id, id);
      return;
    }
    this.deleteWorkflow();
  };

  $backToGrid($event) {
    if (this.isWidget) {
      this.closeClick.emit({ parentId: this.parentId });
    } else {
      this.router.navigate(['/', this.teamName, 'grid']);
    }
  }

  prepAddComponent() {
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  prepEditComponent(component: Node) {
    if (component) {
      this.meta = { params: [], returns: null };
      this.componentParameters = component.parameters;
      this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };

      for (let param of component.parameters) {
        let options = [];
        if (param.type === 'select') {
          const comp = this.availableComponents.find((x) => x.name === component.name);
          if (comp) options = comp.parameters.find((x) => x.name === param.name)?.options;
        }

        const filter = (name, x) => {
          if (name !== 'feature') {
            return x;
          }

          if (!this.workflow?.assets) {
            return [];
          }

          const asset = this.assets.find((asset) => asset.name === this.workflow?.assets[0]);
          if (!asset || !asset.features) {
            return [];
          }

          return asset.features;
        };

        this.meta.params.push({
          name: param.name,
          description: param.description?.replace('\n', '\\n'),
          type: param.type,
          required: false,
          options: filter(param.name, options)
        });

        this.dynamicFormData[param.name] = param.value;
      }

      this.selectedComponent = component;
    }
  }

  resetDevices() {
    this.devices = ['cpu'];
    if (this.allGPUS.length > 0) {
      const count = this.allGPUS[0]['count'];
      for (let i = 0; i < count; i++) {
        this.devices.push(`gpu${i}`);
      }
    }
  }

  public subcribeToFormChanges() {
    this._subscriptions.push(
      this.definitionForm.valueChanges.pipe(debounceTime(2000)).subscribe((value: any) => {
        this.updateWorkflow();
      })
    );
  }

  public async loadAssets() {
    const sub = this.assetsService.load(this.teamName).subscribe((response: Asset[]) => {
      sub.unsubscribe();
      if (!response || response.length === 0) return;

      this.assets = response.filter((x) => x.numProdFiles > 0);
      this.filteredAssets = this.assets;
      Promise.resolve();
    });
  }

  public async loadGPUs() {
    const gpusub = this.gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.resetDevices();
      Promise.resolve();
    });
  }

  filteredAssets = [];
  applyFilter(filterValue: string): void {
    if (!filterValue || filterValue.length === 0) {
      this.filteredAssets = this.assets;
      return;
    }

    this.filteredAssets = this.assets.filter((s) => s.name.includes(filterValue));
  }

  private vault: VaultItem[] = [];

  public async load() {
    this._socketSub = this._socketService.subscribeToRoomMessages('training-started').subscribe((response) => {
      const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
      const verticalPosition: MatSnackBarVerticalPosition = 'top';

      if (this._sessionName !== response.session_name) return;

      this.launched = false;

      if (response.error) {
        const data = { msg: response.error, error: true };
        this.snackbar.openFromComponent(InfoSnackbarComponent, {
          data,
          horizontalPosition: horizontalPosition,
          verticalPosition: verticalPosition
        });
      } else {
        this.openSnackBar('Successfully launched experiment.', 'OK');
      }
    });

    const gpusub = this.gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.resetDevices();
    });

    this.assets = (await this.assetsService.loadAsync(this.teamName)) as any;
    this.assets = this.assets.filter((x) => x.numProdFiles > 0);
    this.filteredAssets = this.assets;
    this.availableModels = await this.dataService.loadModelsAsync(this.teamName);

    const definition: any = await this.definitionsService.getAsync(this.teamName, this.rdsName);
    this.isLoading = false;
    this.availableComponents = definition.availableComponents;
    this.availableComponents = this.availableComponents.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1));

    let workflow: SerializedWorkflow = {
      id: uuidv4(),
      name: this.rdsName,
      description: this.rdsName,
      assets: definition.workflow?.assets,
      transfer: definition.workflow?.transfer,
      device: 'cpu',
      tags: '',
      nodes: [],
      connections: []
    };
    if (definition.workflow?.name?.length) {
      Object.assign(workflow, definition.workflow);
      workflow.nodes.forEach((node) => {
        node.parameters.forEach((param) => {
          if (param?.description) {
            param.description = param.description.replaceAll('\n', '\\n');
          }
        });
      });
    } else {
      console.warn('definition.workflow.name is undefined!');
    }

    this.workflowService.load(workflow);
    this.tags = this.workflow.tags && this.workflow.tags.length > 0 ? new Set(this.workflow.tags) : new Set();

    this.definitionForm.setValue({
      name: this.rdsName,
      description: this.workflow?.description,
      tags: this.tags,
      assets: workflow?.assets || null,
      transfer: workflow?.transfer || null,
      device: workflow?.device ?? 'cpu'
    });

    const sub = this.vaultService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();
      this.isLoading = false;

      if (!response || response.length == 0) return;

      const timestampColumns = ['dateAdded'];
      timestampColumns.forEach((col) => {
        response.forEach((row) => {
          if (row[col]) row[col] = new Date(row[col]);
        });
      });
      this.vault = response;
    });

    this.subcribeToFormChanges();
  }

  async launch(smartfit: boolean) {
    if (this.formDirty) {
      if (!confirm('There are unsaved changes, do you want to save your changes before experimenting?')) return;
      await this.updateWorkflow(true);
    }

    const globals = this.workflow.nodes.find((x) => x.name === 'globals');
    if (!globals) {
      alert('Missing required component: globals');
      return;
    }

    if (this.workflow.connections.find((x) => x.from.id === globals.id || x.to.id === globals.id)) {
      alert('Invalid connection: globals cannot be connected to other components');
      return;
    }

    this.launched = true;

    const { value: device } = this.definitionForm.get('device');
    const sub = this.experimentsService.start(this.teamName, this.rdsName, device, smartfit).subscribe((response: any) => {
      sub.unsubscribe();
      this._sessionName = response.sessionName;
    });
  }

  async compile() {
    if (this.formDirty) {
      if (!confirm('There are unsaved changes, do you want to save your changes before compiling?')) return;
      await this.updateWorkflow(true);
    }

    this.onCompile.emit({ parentId: this.parentId, rdsName: this.rdsName });
  }

  onGPUSelect(event) {}

  viewExperiments(): void {
    if (this.isWidget) {
      this.viewExpermentsClick.emit({ parentId: this.parentId });
    } else {
      this.router.navigate([this.teamName, 'experiments']);
    }
  }

  async updateWorkflow(alertUser: boolean = false) {
    if (!this.definitionForm.valid) {
      if (alertUser) alert('Please update missing values.');
      return;
    }

    const raw = this.definitionForm.getRawValue();

    this.workflowService.update({
      name: raw.name,
      description: raw.description,
      tags: [...this.tags],
      assets: this.definitionForm.get('assets').value,
      transfer: this.definitionForm.get('transfer').value,
      device: this.definitionForm.get('device').value
    });

    let originalName = undefined;
    if (raw.name !== this.rdsName) {
      const response = await this.definitionsService.loadAsync(this.teamName);

      const exists = response.find((x) => x.name.toLowerCase() === raw.name.toLowerCase());
      if (exists) {
        alert(`Definition with name ${raw.name} already exists. Please choose another name.`);
        this.definitionForm.get('name').setValue(this.rdsName);
        this.saveStatus = 'COMPLETE';
        this.formDirty = false;
        return;
      }

      originalName = this.rdsName;
      this.rdsName = raw.name;
    }

    this.saveStatus = 'SAVING';
    try {
      console.debug('trying to save', { teamName: this.teamName, data: this.workflowService.export(), originalName });
      await this.definitionsService.updateAsync(this.teamName, this.workflowService.export(), originalName);
      this.saveStatus = 'COMPLETE';
      this.formDirty = false;
    } catch (error) {
      this.saveStatus = 'ERROR';
      console.error(error);
    }
  }

  editComponent() {
    const path = `/data/${this.teamName}/components/${this.selectedComponent.name}.py`;
    console.debug(path);
    this.onEditFile.emit({ path });
  }

  async retry() {
    await this.updateWorkflow(true);
  }

  addTag(event: MatChipInputEvent) {
    if (event.value) {
      this.tags.add(event.value);
      this.workflowService.addTag(event.value);
      event.chipInput!.clear();
      this.definitionForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  removeTag(keyword: string) {
    this.tags.delete(keyword);
    this.workflowService.removeTag(keyword);
    this.definitionForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  openSnackBar(message: string, action: string) {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  onComponentSelected(selected: string) {
    this.selectedComponent = this.availableComponents.find((x) => x.name === selected);
    var params = [];
    this.meta = null;
    this.componentParameters = [];
    this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };

    for (let param of this.selectedComponent.parameters) {
      const options = param.type === 'select' ? param.options : [];
      params.push({
        name: param.name,
        description: param.description,
        type: param.type,
        required: false,
        options: options
      });

      this.dynamicFormData[param.name] = '';
      this.meta = { params: params, returns: null };
    }
  }

  saveDynamicFormData(data: any) {
    this.componentParameters = [];
    const keys = Object.keys(data);
    for (let key of keys) {
      if (key === 'id') continue;
      const item = this.meta.params.find((x) => x.name === key);
      if (item) {
        this.componentParameters.push({
          name: key,
          value: data[key],
          type: item.type,
          description: item.description,
          optional: item.optional,  // carry over the optional flag
          options: item.options || []      // include options if available
        });
      }
    }
  }

  addComponent() {
    if (this.componentForm) this.saveDynamicFormData(this.componentForm.getFormData());
    this.formDirty = true;

    let node = {
      name: this.selectedComponent.name,
      parameters: this.componentParameters
    };
    const icon = this.selectedComponent.parameters.find((x) => x.name == 'Icon');
    if (icon) {
      node['icon'] = icon.type;
    }
    this.workflowService.insertNode(node);
    this.drawer.close();
  }

  updateComponent(closeDrawer: boolean = true) {
    console.debug('updateComponent', {
      closeDrawer,
      componentForm: this.componentForm
    });
    if (this.componentForm) this.saveDynamicFormData(this.componentForm.getFormData());
    this.formDirty = true;

    this.selectedComponent.parameters = this.componentParameters;
    this.workflowService.updateNode(this.selectedComponent.id, this.selectedComponent);
    if (!closeDrawer) return;

    this.drawer.close();
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  deleteComponent() {
    if (!confirm('Are you sure you want to remove this component?')) {
      return;
    }
    this.formDirty = true;

    this.workflowService.removeNode(this.selectedComponent.id);
    this.drawer.close();
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  async deleteWorkflow() {
    if (!confirm('Are you sure you want to delete this workflow?')) {
      return;
    }

    const sub = this.definitionsService.remove(this.teamName, this.rdsName).subscribe((results) => {
      sub.unsubscribe();

      if (this.isWidget) {
        this.closeClick.emit({ parentId: this.parentId });
      } else {
        this.router.navigate([this.teamName, 'model', 'definitions']);
      }
    });
  }

  @debounce(250)
  onDynamicFormChange() {
    if (this.mode === 'EDITCOMPONENT') this.updateComponent(false);
  }
}
