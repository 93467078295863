import { Component, ElementRef, Input } from '@angular/core';
import { debounce } from 'src/app/directives/debounce.decorator';

export type ChatMessage = { isSelf: boolean; content: string; isError?: boolean };

@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrls: ['./chat-screen.component.scss']
})
export class ChatScreenComponent {
  @Input() messages: ChatMessage[] = [];
  @Input() loading = false;
  @Input() embed = false;

  constructor(private _ref: ElementRef) {}

  @debounce(100)
  public scrollToTop(): void {
    this._ref.nativeElement.scrollTop = 0;
  }

  @debounce(100)
  public scrollToBottom(): void {
    this._ref.nativeElement.scrollTop = this._ref.nativeElement.scrollHeight;
  }
}
