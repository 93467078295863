import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { IdentityService } from 'src/app/services/identity.service';
import { MenuService } from 'src/app/services/menu.service';
import { SettingsService } from 'src/app/services/settings.service';
import { TeamsService } from 'src/app/services/teams.service';
import { VersionService } from 'src/app/services/version.service';
import { WidgetService } from 'src/app/services/widget.service';

const CHANGELOG_URL = 'https://docs.lit.ai/reference/changelog/';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
  @Output() openWidget = new EventEmitter<WidgetService.WidgetName>();

  @HostBinding('class.menubar-top')
  private get _menubarTop() {
    return this.menubarPosition === 'top';
  }

  @HostBinding('class.menubar-left')
  private get _menubarLeft() {
    return this.menubarPosition === 'left';
  }

  public version: string | undefined;
  public chatEnabled: boolean = true;
  public myTeams: { name: string; hosts: string[] }[] = [];

  public get menubarPosition() {
    return this._settings.menubarPosition;
  }

  public get changelogURL(): string {
    if (this.version !== undefined) {
      return `${CHANGELOG_URL}#${this.version}`;
    }
    return CHANGELOG_URL;
  }

  constructor(
    private readonly _router: Router,
    private readonly _menu: MenuService,
    private readonly _version: VersionService,
    private readonly _settings: SettingsService,
    private readonly _teams: TeamsService,
    public identity: IdentityService
  ) {}

  public ngOnInit(): void {
    this._version.get().then(({ version }) => {
      this.version = version;
    });

    const sub = this._teams.load().subscribe((teams) => {
      this.myTeams = teams;
      sub.unsubscribe();
    });
  }

  public navigate(route: string[]): void {
    this._menu.setActive(route[route.length - 1]);
    this._router.navigate(route);
  }

  public toggleDesktopEnvironment(): void {
    this._settings.desktopEnvironment = !this._settings.desktopEnvironment;
  }

  public openUserManager(): void {
    window.location.href = `${ConfigService.keycloakUrl}/admin/${ConfigService.keycloakRealm}/console/#/${ConfigService.keycloakRealm}/users`;
  }

  selectTeam(element) {
    if (element.name === '[New Team]') {
      this._router.navigate(['/']);
      return;
    }

    if (this.identity.me.selectedTeamName === element.name) return;

    localStorage.setItem('lastTeamSelected', element.name);
    this.identity.setTeam(element.name);
    this.identity.setProject(null);
    window.location.href = `${ConfigService.hostUrl}/${element.name}/grid`;
  }
}
