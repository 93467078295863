import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { Workflow } from '../models/workflow';

@Injectable({
  providedIn: 'root'
})
export class LLMWorkflowService {
  constructor(private http: HttpClient) {
    window.$workflow = this;
  }

  public list = (teamName: string) => this.http.get(`${ConfigService.apiUrl}/slash_commands/${teamName}`);

  public load = (teamName: string) =>
    this.http.get<Workflow[]>(`${ConfigService.apiUrl}/data/workflows/${teamName}`);

  public loadAsync = async (teamName: string) =>
    lastValueFrom(this.load(teamName));

  public get = (teamName: string, workflowName: string) =>
    this.http.get<Workflow>(`${ConfigService.apiUrl}/data/workflows/${teamName}/${workflowName}`);

  public getAsync = async (teamName: string, workflowName: string) =>
    lastValueFrom(this.get(teamName, workflowName));

  public init = (teamName: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/workflows/init/${teamName}`, {});

  public initAsync = async (teamName: string) =>
    lastValueFrom(this.init(teamName));

  public duplicate = (teamName: string, name: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/workflows/duplicate/${teamName}`, { name });

  public duplicateAsync = async (teamName: string, name: string) =>
    lastValueFrom(this.duplicate(teamName, name));

  public update = (teamName: string, data: any, originalName?: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/workflows/update/${teamName}`, { data, originalName });

  public updateAsync = async (teamName: string, data: any, originalName?: string) =>
    lastValueFrom(this.update(teamName, data, originalName));

  public remove = (teamName: string, name: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/workflows/delete/${teamName}`, { name });

  public removeAsync = async (teamName: string, name: string) =>
    lastValueFrom(this.remove(teamName, name));
}
