import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamsService {
  constructor(private http: HttpClient) {}

  public load(teamName: string) {
    return this.http.get<any[]>(`${ConfigService.apiUrl}/streams/${teamName}`);
  }

  public symbolSearch(dataFeedUrl, query, limit) {
    let url = `${dataFeedUrl}/search?market=true&limit=${limit}`;
    if(query)
      url += `&query=${query}`
    return lastValueFrom(this.http.get(url));
  }

  public getMarketData(dataFeedUrl, symbol, interval, from, to, countback, strats = []) {
    let url = `${dataFeedUrl}/history?symbol=${symbol}&interval=${interval}&from=${from}&to=${to}&countback=${countback}`;
    if (strats && strats.length > 0) {
      url += `&strats=${strats.join(',')}`;
    }
    return this.http.get(url);
  }

  public getModelData(dataFeedUrl, model, interval, from, to, ensembleMethod, strats = []) {
    let url = `${dataFeedUrl}/model_history?model_name=${model}&interval=${interval}&from=${from}&to=${to}&ensemble_method=${ensembleMethod}`;
    if (strats && strats.length > 0) {
      url += `&strats=${strats.join(',')}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      timeout: 300000
    };
    return this.http.get(url, httpOptions);
  }

  // public getCachedAnalysis(dataFeedUrl, ticker) {
  //   let url = `${dataFeedUrl}/cache/analysis/${ticker}`;
  //   return this.http.get(url);
  // }


  public getPacket(teamName: string, ticker: string, timestamp: string, soup_id: string) {
    const url = `${ConfigService.apiUrl}/packet/${teamName}/${ticker}/${soup_id}?timestamp=${timestamp}`;
    return this.http.get(url);
  }

  public getPackets(teamName: string, ticker: string, timestamp: string) {
    const url = `${ConfigService.apiUrl}/packets/${teamName}/${ticker}/?timestamp=${timestamp}`;
    return this.http.get(url);
  }

  public loadAsync(teamName: string) {
    return lastValueFrom(this.load(teamName));
  }

  public get(teamName: string, streamName: string) {
    return this.http.get(`${ConfigService.apiUrl}/streams/${teamName}/${streamName}`);
  }

  public deleteAsync(teamName: string, streamName: string) {
    return lastValueFrom(this.http.post(`${ConfigService.apiUrl}/streams/${teamName}`, { streamName }));
  }

  public getTimezoneAsync(teamName: string) {
    return lastValueFrom(this.http.get(`${ConfigService.apiUrl}/timezone/${teamName}`));
  }

  public getWorkers(teamName: string) {
    return this.http.get<any[]>(`${ConfigService.apiUrl}/stream/workers/${teamName}`);
  }

  public async getWorkersAsync(teamName: string) {
    return lastValueFrom(this.getWorkers(teamName));
  }

  public createSwagger(teamName: string, streamName: string) {
    return this.http.get(`${ConfigService.apiUrl}/stream/swagger/${teamName}/${streamName}`)
  }

  public async createSwaggerAsync(teamName: string, streamName: string) {
    return lastValueFrom(this.createSwagger(teamName, streamName));
  }

  public getApiStats(teamName: string, streamName: string) {
    return this.http.get(`${ConfigService.apiUrl}/stream/api/stats/${teamName}/${streamName}`)
  }

  public getApiLogs(teamName: string, streamName: string, pageSize: number, pageNum: number, sortColumn: string, sortOrder: string) {
    return this.http.get(`${ConfigService.apiUrl}/stream/api/logs/${teamName}/${streamName}/${pageSize}/${pageNum}/${sortColumn}/${sortOrder}`);
  }

  public async getApiLogsAsync(teamName: string, streamName: string, pageSize: number, pageNum: number, sortColumn: string, sortOrder: string) {
    return await lastValueFrom<any>(this.getApiLogs(teamName, streamName, pageSize, pageNum, sortColumn, sortOrder))
  }
}
