import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WorkflowService } from 'src/app/services/workflow.service';
import { SerializedWorkflow, Workflow, WorkflowComponent } from 'src/app/models/workflow';
import { MatDrawer } from '@angular/material/sidenav';
import { LLMWorkflowService } from 'src/app/services/llmworkflow.service';
import { Node, NodeParameter } from 'src/app/models/workflow-node';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowDrawerMode } from '../../models/definition/definition.component';
import { WorkflowDrawerEvent } from '../../models/definition/workflow-canvas/workflow-canvas.component';
import { Meta } from 'src/app/models/meta';
import { debounce } from 'src/app/directives/debounce.decorator';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { Subscription } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-workflow-widget',
  templateUrl: './workflow-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './workflow-widget.component.scss'],
  providers: [ WorkflowService ]
})
export class WorkflowWidgetComponent extends BaseWidgetComponent implements OnInit {
  @Input() name: string = null;
  public workflow: any;
  public isLoading = true;
  public drawerMode: 'ADDCOMPONENT' | 'EDITCOMPONENT' | 'SETTINGS' = undefined;
  public mode: WorkflowDrawerMode = undefined;
  public selectedComponent: any;
  public meta: Meta = null;
  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' = 'COMPLETE';
  public tags: Set<string> = new Set();
  private componentParameters: NodeParameter[] = [];
  public dynamicFormData: any;
  private availableComponents: WorkflowComponent[] = [];
  public formDirty: boolean = false;
  private _dirtySubscription: Subscription;

  public definitionForm = new FormGroup({
      name: new FormControl<any>('', [Validators.required, Validators.minLength(3)]),
      description: new FormControl<any>(''),
      tags: new FormControl<any>([]),
    });

  @ViewChild(DynamicFormComponent) componentForm: DynamicFormComponent;
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  @Input() isWidget: string;
  @Input() parentId: string;
  @Input() teamName: string;

  @Output() onEditFile = new EventEmitter<{ path: any }>();

  constructor(
    private _widgetService: WidgetService,
    private _elRef: ElementRef,
    private teamsService: TeamsService,
    private _llm: LLMWorkflowService,
    private workflowService: WorkflowService,
  ) {
    super();
    (<any>window).workflow = this;
  }

  ngOnInit(): void {
    this.teamsService.getConfigAsync(this.teamName).then((config) => {
      if (this.name === 'add') {
        this.newWorkflow();
      } else {
        this.loadWorkflow();
      }
    });


  }

  ngOnDestroy(): void {
    if (this._dirtySubscription) {
      this._dirtySubscription.unsubscribe();
    }
  }

  newWorkflow() {
    this._llm
      .initAsync(this.teamName)
      .then((workflow: any) => {
        this.name = workflow.name;
        this.loadWorkflow();
      })
      .catch(console.error);
  }

  loadWorkflow(): void {
    this._dirtySubscription = this.workflowService.dirty$.subscribe(isDirty => {
      if (isDirty) {
        this.formDirty = true;
      }
    });

    this.isLoading = true;
    this._llm.get(this.teamName, this.name).subscribe((definition:any) => {
      this.isLoading = false;
      console.debug("✅ Loaded LLM Workflow JSON:", definition);

      this.availableComponents = definition.availableComponents;
      this.availableComponents = this.availableComponents.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1));

      let workflow: SerializedWorkflow = {
        id: uuidv4(),
        name: definition.workflow?.name || "Untitled Workflow",
        description: definition.workflow?.description || "",
        tags: definition.workflow?.tags || new Set(),
        nodes: [],
        connections: []
      };

      if (definition.workflow?.name?.length) {
        Object.assign(workflow, definition.workflow);

        workflow.nodes.forEach((node) => {
          node.parameters.forEach((param) => {
            if (param?.description) {
              param.description = param.description.replaceAll('\n', '\\n');
            }
          });
        });
      } else {
        console.warn("⚠️ definition.workflow.name is undefined!");
      }

      this.definitionForm.setValue({
        name: this.name,
        description: this.workflow?.description ?? "",
        tags: this.tags ?? new Set()
      });

      console.debug("📌 Final Workflow Object:", workflow);
      this.workflowService.workflow$.subscribe((wf) => {
        if (wf) {
          console.debug("✅ Workflow Loaded", wf);
          this.workflow = wf;
        }
      });
      this.workflowService.load(workflow);

      this.tags = this.workflow.tags && this.workflow.tags.length > 0 ? new Set(this.workflow.tags) : new Set();

      this.definitionForm.setValue({
        name: this.name,
        description: this.workflow?.description,
        tags: this.tags
      });
    });
  }

  $saveWorkflow = (wf: Workflow) => {
    if (!wf.id || this.workflow.id !== wf.id) {
      console.warn('workflow ids do not match', this.workflow.id, wf.id);
      return;
    }
    this.workflow = wf;
    this.updateWorkflow(true);
  };

  $updateDrawer = ($ev: WorkflowDrawerEvent) => {
    const { id, mode } = $ev;
    if (!id || this.workflow.id !== id) {
      console.warn('workflow ids do not match', this.workflow.id, id);
      return;
    }

    const setMode = async () => {
      if (mode) {
        if (this.mode !== mode) this.mode = mode;
        await new Promise((resolve) => setTimeout(resolve, 50));
        if (!this.drawer.opened) await this.drawer.open();
      } else {
        if (this.drawer.opened) await this.drawer.close();
        if (this.mode !== mode) this.mode = mode;
      }
    };

    if (mode) {
      if (mode == 'ADDCOMPONENT') {
        this.prepAddComponent();
      } else if (mode == 'EDITCOMPONENT') {
        this.prepEditComponent($ev.component);
      } else if (mode == 'MULTI_EDIT') {
        // New branch for multi-node editing.
        this.prepMultiEdit();
      }
    }
    setMode();
    console.debug("$updateDrawer", $ev, this.mode)
  };

  prepMultiEdit() {
    const selectedIds = this.workflow.selectedNodes || [];
    if (!selectedIds.length) {
      this.selectedComponent = null;
      this.meta = { params: [], returns: null };
      this.componentParameters = [];
      this.dynamicFormData = null;
      return;
    }
    const firstNode = this.workflowService.getNodeByID(selectedIds[0]);
    this.selectedComponent = firstNode;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };

    if (firstNode) {
      for (let param of firstNode.parameters) {
        console.debug(`Parameter ${param.name}: optional =`, param.optional);
        // Only include parameters that are marked as optional
        if (!param.optional) continue;

        let commonValue = param.value;
        for (let nodeId of selectedIds.slice(1)) {
          const node = this.workflowService.getNodeByID(nodeId);
          if (node) {
            const otherParam = node.parameters.find(p => p.name === param.name);
            if (!otherParam || otherParam.value !== commonValue) {
              commonValue = ''; // Mixed values; leave blank.
              break;
            }
          }
        }
        this.meta.params.push({
          name: param.name,
          description: param.description?.replace('\n', '\\n'),
          type: param.type,
          // Set required as the inverse of optional
          required: !param.optional,
          options: param.options || [],
          // Use the normalized property "optional"
          optional: param.optional
        });
        this.dynamicFormData[param.name] = commonValue;
        this.componentParameters.push({
          name: param.name,
          value: commonValue,
          type: param.type,
          description: param.description,
          optional: param.optional,
          options: param.options || []
        });
      }
    }
    console.debug('Filtered meta.params:', this.meta.params);
  }

  closeDrawer() {
    this.drawer.close();
    this.drawerMode = undefined;
  }

  onComponentSelected(selected: string) {
    this.selectedComponent = this.availableComponents.find((x) => x.name === selected);
    let params = [];
    this.meta = null;
    this.componentParameters = [];
    this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };

    for (let param of this.selectedComponent.parameters) {
      const options = param.type === 'select' ? param.options : [];
      params.push({
        name: param.name,
        description: param.description,
        type: param.type,
        optional: param.optional,
        required: !param.optional,  // required is the inverse of optional
        options: options
      });
      this.dynamicFormData[param.name] = '';
      this.meta = { params: params, returns: null };
    }
  }

  prepAddComponent() {
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  editPrompt() {
    const prompt:string = this.selectedComponent?.parameters[0]?.value;
    const path = `/data/${this.teamName}/prompts/${prompt}`;
    this.onEditFile.emit({ path });
  }

  editComponent() {
    const path = `/data/${this.teamName}/components/llm/${this.selectedComponent.name}.py`;
    this.onEditFile.emit({ path });
  }

  prepEditComponent(component: Node) {
    if (component) {
      this.meta = { params: [], returns: null };
      this.componentParameters = component.parameters;
      this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };

      for (let param of component.parameters) {
        let options = [];
        if (param.type === 'select') {
          const comp = this.availableComponents.find((x) => x.name === component.name);
          if (comp) options = comp.parameters.find((x) => x.name === param.name)?.options;
        }

        const filter = (name, x) => {
          if (name !== 'feature') {
            return x;
          }

          return [];
        };

        this.meta.params.push({
          name: param.name,
          description: param.description?.replace('\n', '\\n'),
          type: param.type,
          optional: param.optional,
          required: !param.optional,
          options: filter(param.name, options)
        });

        this.dynamicFormData[param.name] = param.value;
      }

      this.selectedComponent = component;
    }
  }

  saveDynamicFormData(data: any) {
    this.componentParameters = [];
    const keys = Object.keys(data);
    for (let key of keys) {
      if (key === 'id') continue;
      const item = this.meta.params.find((x) => x.name === key);
      if (item) {
        this.componentParameters.push({
          name: key,
          value: data[key],
          type: item.type,
          description: item.description,
          // Use the normalized "optional" property
          optional: item.optional,
          options: item.options || []
        });
      }
    }
  }

  addComponent() {
    if (this.componentForm) this.saveDynamicFormData(this.componentForm.getFormData());

    let node = {
      name: this.selectedComponent.name,
      parameters: this.componentParameters
    };
    const icon = this.selectedComponent.parameters.find((x) => x.name == 'Icon');
    if (icon) {
      node['icon'] = icon.type;
    }
    this.workflowService.insertNode(node);
    console.debug(node)
    this.drawer.close();
  }

  updateComponent(closeDrawer: boolean = true) {
    console.debug('updateComponent', {
      closeDrawer,
      componentForm: this.componentForm
    });
    if (this.componentForm) this.saveDynamicFormData(this.componentForm.getFormData());
    this.selectedComponent.parameters = this.componentParameters;
    this.workflowService.updateNode(this.selectedComponent.id, this.selectedComponent);
    if (!closeDrawer) return;

    this.drawer.close();
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  updateMultiComponent(closeDrawer: boolean = true) {
    if (this.componentForm) this.saveDynamicFormData(this.componentForm.getFormData());
    const changes = this.componentParameters;
    const selectedIds = this.workflowService.workflow.selectedNodes || [];
    for (const nodeId of selectedIds) {
      const node = this.workflowService.getNodeByID(nodeId);
      if (node) {
        for (const change of changes) {
          // Do not update the unique "name" property.
          if (change.name === 'name') continue;
          const existing = node.parameters.find(p => p.name === change.name);
          if (existing) {
            existing.value = change.value;
          } else {
            node.parameters.push(change);
          }
        }
        this.workflowService.updateNode(node.id, node);
      }
    }
    if (closeDrawer) {
      this.drawer.close();
      // Optionally clear the selection or reset form state.
      this.selectedComponent = null;
      this.meta = { params: [], returns: null };
      this.componentParameters = [];
      this.dynamicFormData = null;
    }
  }

  deleteComponent() {
    if (!confirm('Are you sure you want to remove this component?')) {
      return;
    }

    this.workflowService.removeNode(this.selectedComponent.id);
    this.drawer.close();
    this.selectedComponent = null;
    this.meta = { params: [], returns: null };
    this.componentParameters = [];
    this.dynamicFormData = null;
  }

  @debounce(250)
  onDynamicFormChange() {
    if (this.mode === 'EDITCOMPONENT') {
      this.updateComponent(false);
    } else if (this.mode === 'MULTI_EDIT') {
      this.updateMultiComponent(false);
    }
  }

  addTag(event: MatChipInputEvent) {
    if (event.value) {
      this.tags.add(event.value);
      this.workflowService.addTag(event.value);
      event.chipInput!.clear();
      this.definitionForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  removeTag(keyword: string) {
    this.tags.delete(keyword);
    this.workflowService.removeTag(keyword);
    this.definitionForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  async updateWorkflow(alertUser: boolean = false) {
    if (!this.definitionForm.valid) {
      if (alertUser) alert('Please update missing values.');
      return;
    }

    const raw = this.definitionForm.getRawValue();

    this.workflowService.update({
      name: raw.name,
      description: raw.description,
      tags: [...this.tags]
    });

    let originalName = undefined;
    if (raw.name !== this.name) {
      const response = await this._llm.loadAsync(this.teamName);

      const exists = response.find((x) => x.name.toLowerCase() === raw.name.toLowerCase());
      if (exists) {
        alert(`Definition with name ${raw.name} already exists. Please choose another name.`);
        this.definitionForm.get('name').setValue(this.name);
        this.saveStatus = 'COMPLETE';
        this.formDirty = false;
        return;
      }

      originalName = this.name;
      this.name = raw.name;
    }

    this.saveStatus = 'SAVING';
    try {
      console.debug('trying to save', { teamName: this.teamName, data: this.workflowService.export(), originalName });
      await this._llm.updateAsync(this.teamName, this.workflowService.export(), originalName);
      this.saveStatus = 'COMPLETE';
      this.formDirty = false;
    } catch (error) {
      this.saveStatus = 'ERROR';
      console.error(error);
    }
  }
}
