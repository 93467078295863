import { Color } from '@angular-material-components/color-picker';
import { Point, SerializedPoint } from './point';
import { v4 as uuidv4 } from 'uuid';

// Normalize on "optional" here.
export interface NodeParameter {
  name: string;
  value: string;
  type: string;
  description: string;
  optional: boolean;      // Use a single property: true means the field is optional.
  options?: any[];        // Optional property for select-type parameters
}

export interface SerializedNode {
  name: string;
  id: string;
  icon: string;
  position: SerializedPoint;
  parameters: NodeParameter[];
  color?: string;
}

export interface NodeData extends SerializedNode {
  scale: number;
  outPortOffset: Point;
  inPortOffset: Point;
  width: number;
  height: number;
}

export class Node {
  public name: string = 'Node';
  public id: string = uuidv4();
  public icon: string = '';
  public color: Color = new Color(0x61, 0x6a, 0x74);
  public position: Point = new Point();
  public scale: number = 1;
  public outPortOffset: Point;
  public inPortOffset: Point;
  public width: number = 80;
  public height: number = 79;
  public parameters: NodeParameter[] = [];

  get outPortPosition(): Point {
    if (!this.position) return new Point();
    // Create a fresh copy of the position and add the offset.
    let pos = new Point(this.position.x, this.position.y);
    return pos.add(this.outPortOffset);
  }

  get inPortPosition(): Point {
    if (!this.position) return new Point();
    let pos = new Point(this.position.x, this.position.y);
    return pos.add(this.inPortOffset);
  }

  constructor(node: Partial<Node> | Partial<NodeData>) {
    Object.assign(this, node);
    this.position = new Point(node.position?.x, node.position?.y);
    // Normalize parameters: map any "optional" value (or fallback from the old is_optional if present)
    this.parameters = node.parameters
      ? node.parameters.map(p => ({
          ...p,
          optional: p.optional !== undefined ? p.optional : ((<any>p).is_optional !== undefined ? (<any>p).is_optional : false),
          options: p.options || []
        }))
      : [];
    this.outPortOffset = new Point(45 * this.scale, 0);
    this.inPortOffset = new Point(-45 * this.scale, 0);

    const { color } = node;
    if (typeof color === 'string' && color.length === 6) {
      const [r, g, b] = [0, 2, 4].map((i) => parseInt(color.substring(i, i + 2), 16));
      this.color = new Color(r, g, b);
    }
  }

  public clone(): Node {
    // Return a deep clone of the node including its parameters and position.
    return new Node({
      ...this,
      position: { x: this.position.x, y: this.position.y },
      parameters: this.parameters.map(p => ({ ...p }))
    });
  }

  public toString(): string {
    return `${this.id}:${this.name}`;
  }

  public serialize = (): SerializedNode => ({
    name: this.name,
    parameters: this.parameters.map(p => ({ ...p })),
    id: this.id,
    icon: this.icon,
    color: this.color.toHex(false),
    position: this.position.serialize()
  });

  public static serialize = (node: Node) => node.serialize();

  // New helper method to update or add a parameter by name.
  public updateParameter(name: string, value: string): void {
    const param = this.parameters.find(p => p.name === name);
    if (param) {
      param.value = value;
    } else {
      // Optionally, add the parameter if it doesn't exist.
      this.parameters.push({ name, value, type: 'string', description: '', optional: false });
    }
  }
}
