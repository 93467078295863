import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import {
  SciChartSurface,
  NumericAxis,
  FastLineRenderableSeries,
  XyDataSeries,
  TSciChart,
} from 'scichart';

@Component({
  selector: 'app-sparkline-chart',
  template: `<div [id]="chartId" class="sparkline-container"></div>`,
  styles: [
    `
      .sparkline-container {
        width: 100%;
        height: 50px;
      }
    `,
  ],
})
export class SparklineChartComponent implements AfterViewInit, OnDestroy {
  @Input() data: number[] = [];
  @Input() chartId: string = ''; // Chart ID must be unique

  private wasmContext: TSciChart | undefined;
  private sciChartSurface: SciChartSurface | undefined;

  async ngAfterViewInit(): Promise<void> {
    const element = document.getElementById(this.chartId);

    if (!element) {
      console.error(`Chart div element with the ID "${this.chartId}" is not present in the DOM.`);
      return;
    }

    const { sciChartSurface, wasmContext } = await SciChartSurface.create(this.chartId);
    this.wasmContext = wasmContext;
    this.sciChartSurface = sciChartSurface;

    sciChartSurface.xAxes.add(new NumericAxis(wasmContext, { isVisible: false }));
    sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { isVisible: false }));

    const dataSeries = new XyDataSeries(wasmContext, {
      xValues: this.data.map((_, index) => index),
      yValues: this.data,
    });

    const lineSeries = new FastLineRenderableSeries(wasmContext, {
      dataSeries,
      stroke: '#00bcd4',
      strokeThickness: 1,
    });

    sciChartSurface.renderableSeries.add(lineSeries);
  }
  
  ngOnDestroy() {
    if (this.sciChartSurface) {
      this.sciChartSurface.delete();
    }
  }
}
