import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  constructor(private http: HttpClient) {}

  public load = (teamName: string) => this.http.get(`${ConfigService.apiUrl}/jobs/${teamName}`);

}
