import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { IdentityService } from 'src/app/services/identity.service';
import { LitTheme, ChartTheme, SettingsService } from 'src/app/services/settings.service';

export type FullTheme = { bg: string; mode: LitTheme; chart: ChartTheme };

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  @ViewChild('customBackgroundInput') customBackgroundInput: ElementRef<HTMLInputElement>;

  @HostBinding('class.widget')
  @Input()
  isWidget: boolean = false;

  tab: string = 'home';
  tabs = [
    { icon: 'home', name: 'home', title: 'Home' },
    { icon: 'brush', name: 'personalization', title: 'Personalization' },
    { icon: 'stream', name: 'data', title: 'Data streams' },
    { icon: 'show_chart', name: 'chart', title: 'Chart streams' },
    { icon: 'local_fire_department', name: 'chat', title: 'Chat' }
  ];
  commonTabs = [this.tabs[1], this.tabs[2], this.tabs[3]];
  providedBackgrounds = ['url(/assets/yosemite-2.webp)', 'url(/assets/manhattan.webp)'];
  themes: FullTheme[] = [
    {
      bg: 'url(/assets/yosemite-2.webp)',
      mode: 'light',
      chart: 'auto'
    },
    {
      bg: 'url(/assets/manhattan.webp)',
      mode: 'dark',
      chart: 'navy'
    }
  ];
  backgroundType: 'image' | 'color';
  fillColors = [
    '#ff8c00',
    '#e81123',
    '#d13438',
    '#c30052',
    '#bf0077',
    '#9a0089',
    '#881798',
    '#744da9',
    '#10893e',
    '#107c10',
    '#018574',
    '#2d7d9a',
    '#0063b1',
    '#6b69d6',
    '#8e8cd8',
    '#8764b8',
    '#038387',
    '#486860',
    '#525e54',
    '#7e735f',
    '#4c4a48',
    '#515c6b',
    '#4a5459',
    '#000000'
  ];

  public get username() {
    return this.identity.me.username;
  }

  public get teamName() {
    return this.identity.me.selectedTeamName ?? 'none';
  }

  constructor(
    public identity: IdentityService,
    public settings: SettingsService,
    public config: ConfigService
  ) {
    this.backgroundType = this.settings.backgroundType;
  }

  selectTheme(theme: FullTheme) {
    this.settings.theme = theme.mode;
    this.settings.appBackground = theme.bg;
    this.settings.chartTheme = theme.chart;
  }

  $uploadImage() {
    this.uploadImage()
      .then((imageURL) => {
        this.settings.appBackground = `url(${imageURL})`;
      })
      .catch((error) => {
        if (error instanceof Event && error.type === 'cancel') return;
        else throw error;
      });
  }

  async uploadImage() {
    return new Promise<string>((resolve, reject) => {
      const input = document.createElement('input');
      Object.assign(input, { type: 'file', accept: 'image/*' });
      input.click();

      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      };
      input.addEventListener('cancel', reject);
      input.onerror = reject;
    });
  }

  $clickSelectBackgroundColor() {
    this.customBackgroundInput.nativeElement.click();
  }

  $selectBackgroundColor(color: Event | string) {
    if (color instanceof Event) color = this.customBackgroundInput.nativeElement.value;
    else this.customBackgroundInput.nativeElement.value = color;

    this.settings.appBackground = color as string;
  }
}
