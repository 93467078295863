import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

type ChatResponse = {
  error?: {
    repr: string;
    errors?: { loc: string[]; msg: string; type: string }[];
  };
  output?: string;
  steps: string[];
};

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) {}

  public chat = (team: string, username: string, msg: string, sessionId, project: string) =>
    this.http.post<ChatResponse>(`${ConfigService.chatUrl}/${sessionId}/chat`, { team, username, msg, project });

  public chatAsync = async (team: string, username: string, msg: string, sessionId, project: string) =>
    lastValueFrom(this.chat(team, username, msg, sessionId, project));

  public history = (sessionId: string) => this.http.get<unknown[]>(`${ConfigService.chatUrl}/${sessionId}/history`);

  public historyAsync = async (sessionId: string) => lastValueFrom(this.history(sessionId));
}
