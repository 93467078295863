import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { WidgetService } from 'src/app/services/widget.service';
import { StratsService } from 'src/app/services/strats';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { IndicatorsService } from 'src/app/services/indicators.service';
import { QueryBuilderConfig } from '../../query-builder/query-builder.interfaces';

@Component({
  selector: 'app-strategy-widget',
  templateUrl: './strategy-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './strategy-widget.component.scss']
})
export class StrategyWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() ticker: string;
  @Input() modelName: string;
  @Input() strats: string[];

  @Output() onLoadBreakout = new EventEmitter<{
    parentId: string;
    ticker: string;
    modelName: string;
    strategyName: string;
    days: number;
  }>();

  public codeEditorOptions = {
    theme: 'vs-dark',
    language: 'python',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };

  public strategies = [];
  public selectedStrategy = null;
  private codeSubject = new Subject<string>();
  strategyPath: string = null;

  private _query = {
    condition: 'and',
    rules: [
      {
        condition: 'and',
        rules: [
          { field: 'ago', operator: '=', value: 1 },
          { field: 'timespan', operator: '=', value: 'minute' },
          { field: 'prediction', operator: '>=', value: 0.5 }
        ]
      },
      {
        condition: 'and',
        rules: [
          { field: 'ago', operator: '=', value: 2 },
          { field: 'timespan', operator: '=', value: 'minute' },
          { field: 'pullback', operator: '>=', value: 0.5 }
        ]
      }
    ]
  };

  public get query(): object {
    return this._query;
  }

  public set query(v: object) {
    const oldValue = this._query;
    this._query = <any>v;
    if (v == null || oldValue == null) {
      return;
    }

    const newEncodedValue = JSON.stringify(v, null, '\t');
    if (this.selectedStrategy.lastSavedValue !== null && newEncodedValue !== this.selectedStrategy.lastSavedValue) {
      console.log(`Saving ${this.selectedStrategy.path}`);
      this.fileService.postAsync(this.teamName, this.selectedStrategy.path, 'json', v);
      this.selectedStrategy.lastSavedValue = newEncodedValue;
    }
  }

  // Sourced from: https://pixabay.com/sound-effects/search/notification/
  // "Royalty-free notification sound effects."
  alerts = [
    { name: 'Stop', value: 'stop-13692' },
    { name: 'Notification', value: 'notification-sound-7062' },
    { name: 'Email', value: 'the-notification-email-143029' },
    { name: 'Announcement', value: 'announcement-sound-4-21464' },
    { name: 'Message', value: 'message-ringtone-21467' },
    { name: 'Cash Register', value: 'cash-register-kaching-sound-effect-125042' }
  ];

  config: QueryBuilderConfig = {
    fields: {
      cooldown: {
        name: 'Cooldown (mins)',
        type: 'number',
        operators: ['<', '<=']
      },
      prediction: { name: 'Prediction', type: 'number' },
      Volume: { name: 'Volume', type: 'number' },
      Trades: { name: 'Trades', type: 'number' },
      spike: {
        name: 'Spike',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      flat: {
        name: 'Flat',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      flat_abs: {
        name: 'Flat (abs)',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      pullback: {
        name: 'Pullback (%)',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      ago: {
        name: 'Minutes Ago',
        type: 'number',
        operators: ['==', '<=', '>=', '>', '<']
      },
      marker: {
        name: 'Marker',
        type: 'category',
        options: [
          { name: 'Up', value: 'up' },
          { name: 'Down', value: 'down' }
        ]
      },
      color: {
        name: 'Color',
        type: 'string',
        operators: ['==']
      },
      alert: {
        name: 'Alert',
        type: 'category',
        options: this.alerts
      }
    }
  };

  strategyType = 'querybuilder';
  showCode: boolean = false;
  code: string = null;
  public hiddenStrategyList = new SelectionModel<any>(true, []);

  constructor(
    private fileService: FileService,
    private _stratsService: StratsService,
    private _widgetService: WidgetService,
    private _indicatorsService: IndicatorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
    if ((<any>window).strats) {
      (<any>window).strats.push(this);
    } else {
      (<any>window).strats = [this];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  init() {
    this._stratsService.load(this.teamName).subscribe((response) => {
      this.strategies = <any[]>response;
      this.strats.forEach((strat) => {
        this.hiddenStrategyList.toggle(this.strategies.find((x) => x.name == strat));
      });
    });

    this._subs.add = this._widgetService.tickerUpdate$.subscribe((response: any) => {
      if (response.parentId !== this.parentId) return;
      this.ticker = response.ticker;
    });

    this._subs.add = this._indicatorsService.load(this.teamName).subscribe(async (response) => {
      const indicators = response?.filter((x) => x.type).sort();
      indicators.forEach((indicator) => {
        this.config.fields[indicator.name] = { name: indicator.description, type: 'number', operators: ['<', '<=', '>', '>='] };
      });
    });
  }

  selectStrategy(event, strategy) {
    this.selectedStrategy = strategy;
    this.query = null;
    this.code = null;
    if (strategy.path.endsWith('.json')) {
      this.fileService.get(this.teamName, strategy.path).then((code) => {
        this.query = JSON.parse(code);
        this.selectedStrategy.lastSavedValue = code;
      });
    } else if (strategy.path.endsWith('.py') || strategy.path.endsWith('.llm')) {
      this.fileService.get(this.teamName, strategy.path).then((code) => {
        this.code = code;
        this.selectedStrategy.lastSavedValue = code;
      });
    }
  }

  async clickStrategyCheckbox(event, strategy) {
    this.hiddenStrategyList.toggle(strategy);
    this._widgetService.loadModelData$.next({ parentId: this.id });
    const strats = this.hiddenStrategyList.selected.map((x) => x.name);
    this._widgetService.stratsUpdate$.next({ parentId: this.parentId, strats });
  }

  loadBreakout($event, strategy) {
    const days: number = 30;
    this.onLoadBreakout.emit({ parentId: this.id, ticker: this.ticker, modelName: this.modelName, strategyName: strategy.name, days });
  }

  public codeChanged(event) {
    this.codeSubject.next(event);
  }

  codeKeypress($event) {
    if (($event.ctrlKey || $event.metaKey) && $event.key == 's') {
      $event.stopPropagation();
      $event.preventDefault();
  
      const fileType = this.selectedStrategy.path.endsWith('.llm') ? 'llm' : 'py';
      this.fileService.postAsync(this.teamName, this.selectedStrategy.path, fileType, this.code);
    } else if (($event.ctrlKey || $event.metaKey) && $event.key == 'x') {
      $event.stopPropagation();
      $event.preventDefault();
      this.selectedStrategy = null;
    }
  }

  close() {
    if (this.selectedStrategy && this.selectedStrategy.type == 'python') {
      this.selectedStrategy = null;
    } else {
      super.close();
    }
  }
}
