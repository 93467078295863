import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Point } from 'src/app/models/point';
import { CanvasViewType } from 'src/app/models/workflow-canvas';
import SVGCanvasEventManager, { MoveEvent, TapEvent } from 'src/app/models/svg-canvas-event-manager';
import { Node } from 'src/app/models/workflow-node';
import { WorkflowService } from 'src/app/services/workflow.service';
import { Workflow } from 'src/app/models/workflow';
import { Subscription } from 'rxjs';

@Component({
  selector: '[wfc-node]',
  templateUrl: './workflow-canvas-node.component.html',
  styleUrls: ['./workflow-canvas-node.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasNodeComponent implements OnInit, OnDestroy {
  public readonly TITLE_ZOOM_THRESHOLD = 0.7;
  public node: Node;
  public dragging: boolean = false;
  public transform: string = '';
  private _subscriptions: Subscription[] = [];
  private _em: SVGCanvasEventManager;

  @Input() id: string;
  @Input() canvasView: CanvasViewType;
  @Input() snapToGrid: boolean;
  @Input() connectionCandidate: boolean;

  @Output() drag = new EventEmitter<{ node: Node; e: Event }>();
  @Output() end = new EventEmitter<{ node: Node; e: Event }>();

  // Use a getter that checks both single and multi selection.
  public get selected(): boolean {
    const wf = this._workflowService.workflow;
    return (wf.selectedNodes && wf.selectedNodes.includes(this.id)) || wf.selectedNode === this.id;
  }

  public get unselected(): boolean {
    const wf = this._workflowService.workflow;
    return !this.selected && wf.selectedNodes.length > 0;
  }

  constructor(
    private _host: ElementRef,
    private _workflowService: WorkflowService
  ) {}

  public ngOnInit(): void {
    this.$nextWorkflow(this._workflowService.workflow);
    this._subscriptions.push(this._workflowService.workflow$.subscribe(this.$nextWorkflow.bind(this)));
    this._em = new SVGCanvasEventManager(this._host.nativeElement);
    this._em.$tap(this.$tap);
    this._em.$move(this.$move);
    this._em.$moveEnd(this.$moveEnd);
    this._snapToGrid();
  }

  public ngOnDestroy(): void {
    this._em.setdown();
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public $nextWorkflow = (wf: Workflow) => {
    const node = this._workflowService.getNodeByID(this.id);
    if (node) {
      this.node = node;
      this.transform = `translate(${this.node.position.x}, ${this.node.position.y})`;
      // No longer maintain a local selectedNode – we rely on the workflow's selection state.
    }
  };

  public $tap = ($ev: TapEvent): void => {
    $ev.stopPropagation();
    console.debug('Tap event received:', $ev);
    // Try to see if modifier keys exist directly...
    console.debug('ctrlKey:', ($ev as any).ctrlKey, 'shiftKey:', ($ev as any).shiftKey);
    // If they are missing, see if the original event is stored somewhere:
    console.debug('Detail:', $ev.detail);

    // Proceed using our workaround:
    const originalEvent = ($ev.detail && ($ev.detail.originalEvent || $ev.detail)) || $ev;
    if (((<any>originalEvent).ctrlKey || (<any>originalEvent).shiftKey)) {
      // Multi-select logic
      const wf = this._workflowService.workflow;
      if (wf.selectedNodes && wf.selectedNodes.includes(this.id)) {
        this._workflowService.removeNodeFromSelection(this.id);
      } else {
        const current = wf.selectedNodes || [];
        this._workflowService.selectNodes([...current, this.id]);
      }
    } else {
      // Single selection – clear multi-selection and select only this node.
      this._workflowService.selectNodes([this.id]);
    }
  };

  public $move = ($ev: MoveEvent): void => {
    $ev.stopPropagation();
    this.dragging = true;
    const scale = (this.canvasView && this.canvasView.scale) || 1;
    const position = new Point(
      this.node.position.x + ($ev.detail.delta.x / scale),
      this.node.position.y + ($ev.detail.delta.y / scale)
    );
    this._workflowService.updateNode(this.id, { position });
  };

  public $moveEnd = ($ev: MoveEvent): void => {
    $ev.stopPropagation();
    this.dragging = false;
    const position = this._snapToGrid();
    this._workflowService.updateNode(this.id, { position });
  };

  public $drag = ($ev: { node: Node; e: Event }): void => this.drag.emit($ev);
  public $end = ($ev: { node: Node; e: Event }): void => this.end.emit($ev);

  private _snapToGrid(): Point {
    if (!this.snapToGrid) return this.node.position;
    const grid = 100 * 0.5;
    return new Point(
      Math.round(this.node.position.x / grid) * grid,
      Math.round(this.node.position.y / grid) * grid
    );
  }
}
