import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  SciChartSurface,
  NumericAxis,
  NumberRange,
  ZoomExtentsModifier,
  ZoomPanModifier,
  MouseWheelZoomModifier,
  CursorModifier,
  XyDataSeries,
  EAutoRange,
  DataPointSelectionModifier,
  FastLineRenderableSeries,
  SciChartVerticalGroup,
  RolloverModifier,
  ESelectionMode,
  TModifierKeys,
  DateTimeNumericAxis,
  TSciChart,
  CustomAnnotation,
  EVerticalAnchorPoint,
  EHorizontalAnchorPoint,
  XAxisDragModifier,
  YAxisDragModifier,
  XyzSeriesInfo,
  SeriesInfo,
  EZoomState
} from 'scichart';
import { StreamsService } from 'src/app/services/streams.service';
import { appTheme } from '../../../modules/shared/material-theme';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/services/settings.service';
import { ModelIndicatorsService } from 'src/app/services/model-indicators-service';
import { ConfigService } from 'src/app/services/config.service';
import { WidgetService } from 'src/app/services/widget.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { StratsService } from 'src/app/services/strats';
import { SelectionModel } from '@angular/cdk/collections';
import { SocketService } from 'src/app/services/socket.service';
import { debounce } from 'src/app/directives/debounce.decorator';

function debug(...args) {
  // console.log(...args);
}

function formatDate(date) {
  return new Date(date*1000).toISOString()
}

@Component({
  selector: 'app-model-chart-widget',
  templateUrl: './model-chart-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './model-chart-widget.component.scss']
})
export class ModelChartWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @Input() modelName: string;
  @Input() modelNames: string[];
  @Input() streamName: string;
  @Input() ticker: string;
  @Input() interval: string;
  @Input() parentChartData: any[] = [];

  @Output() onPredictSelect = new EventEmitter<{ parentId: string; timestamp: string; modelName: string; modelValues: any }>();
  @Output() onStrategyEditor = new EventEmitter<{ parentId: string; modelName: string; name: string }>();

  private graphIncrement: number = 20000;
  public ensembleMethod = 'mean';
  public title: string = '';
  public models = [];
  public modelxValues = [];
  public modelValues = [];
  public dataFeedUrl: string;
  public modelWasmContext: TSciChart | undefined;
  public modelChartSurface: SciChartSurface | undefined;
  public modelChartXAxes = null;
  public seriesColor = '#00bcd4';
  public filter = null;
  applyFilter(value) {
    this.filter = value;
  }
  public get filteredModels(): string[] {
    if (!this.filter) return this.models;
    const retval = this.models.filter((x) => x.toLowerCase().indexOf(this.filter.toLowerCase()) != -1);
    if (retval.length == 0) return this.models;
    return retval;
  }

  public modelModifiers = {
    zoomExtents: new ZoomExtentsModifier(),
    zoomPan: new ZoomPanModifier(),
    mouseWheelZoom: new MouseWheelZoomModifier(),
    cursor: new CursorModifier({
      crosshairStroke: '#eee',
      axisLabelFill: '#000',
      showTooltip: true,
      tooltipDataTemplate: (seriesInfos: SeriesInfo[], tooltipTitle: string) => {
        const valuesWithLabels: string[] = [];
        const xyzSeriesInfo = seriesInfos[0] as XyzSeriesInfo;
        // @ts-ignore
        if (xyzSeriesInfo) {
          const annotationMap = JSON.parse(this.lastAnnotationMap);
          const map = annotationMap.find((x) => x.timestamp == xyzSeriesInfo.xValue);
          if (map) {
            const [annotation, color, alert, strategy] = map.annotation.split(':');

            if (Object.keys(this.reports).length > 0 && this.reports[this.ticker] && this.reports[this.ticker][this.modelName]) {
              const tooltip = this.reports[this.ticker][this.modelName][strategy];
              if (tooltip) {
                Object.keys(tooltip).forEach((key) => {
                  const calls = tooltip[key]['calls'];
                  const wins = tooltip[key]['wins'];
                  let win_percent = tooltip[key]['win_percent'];
                  if (Number(win_percent) === win_percent) {
                    win_percent = (win_percent * 100).toPrecision(3);
                    valuesWithLabels.push(`${key} ${wins}/${calls} (${win_percent}%)`);
                  }
                });
              }
            }

          }
        }
        return valuesWithLabels;
      }
    }),
    dataPoint: new DataPointSelectionModifier(),
    rolloverModifier: new RolloverModifier({ showTooltip: false, modifierGroup: 'group1' }),
    xAxisDrag: new XAxisDragModifier(),
    yAxisDrag: new YAxisDragModifier()
  };

  reports = null;
  modelData = [];
  annotations = [];
  strats = [];
  firstTime = false;
  chartData = [];
  old_model = null;
  last_exception = null;
  lastAnnotationMap = null;

  private _intervalId: number = null;
  private _socketSub: any;

  public strategies = [];
  public hiddenStrategyList = new SelectionModel<any>(true, []);

  // Sourced from: https://pixabay.com/sound-effects/search/notification/
  // "Royalty-free notification sound effects."
  alerts = [
    { name: 'Stop', value: 'stop-13692' },
    { name: 'Notification', value: 'notification-sound-7062' },
    { name: 'Email', value: 'the-notification-email-143029' },
    { name: 'Announcement', value: 'announcement-sound-4-21464' },
    { name: 'Message', value: 'message-ringtone-21467' },
    { name: 'Cash Register', value: 'cash-register-kaching-sound-effect-125042' }
  ];

  public modifierKeys: TModifierKeys;
  public verticalGroup;
  public selectedPrediction = null;

  constructor(
    private streamsService: StreamsService,
    private _settingsService: SettingsService,
    private modelIndicatorsService: ModelIndicatorsService,
    private _widgetService: WidgetService,
    private _stratsService: StratsService,
    private _socketService: SocketService,
    private _snackbar: MatSnackBar
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._widgetService.annotationsUpdate$.next({ myId: this.id, parentId: this.parentId, annotationMap: [] });

    if (this._intervalId) {
      clearInterval(this._intervalId);
    }

    if (this._socketSub) {
      this._socketSub.unsubscribe();
    }

    super.ngOnDestroy();
  }

  ngOnInit() {
    this.modelNames = [this.modelName];
    this.init();
    if ((<any>window).models) {
      (<any>window).models.push(this);
    } else {
      (<any>window).models = [this];
    }

    this.modelModifiers.rolloverModifier.modifierGroup = this.parentId;
  }

  public initTraffic() {

    const sub = this.streamsService.get(this.teamName, this.streamName).subscribe(async (response: any) => {
      sub.unsubscribe();
      if (response.hasOwnProperty('parameters')) {
        const keys = Object.keys(response.parameters);
        if (keys.includes('graph_increment')) {
          this.graphIncrement = response.parameters.graph_increment;
        }
      }
    });

    if (this._intervalId) clearInterval(this._intervalId);
    if (this._socketSub)  {
       this._socketSub.unsubscribe();
       this._socketSub = null;
    }

    if (this._settingsService.chartTraffic === 'SOCKET') {

      this._socketSub = this._socketService.subscribeToRoomMessages('predict').subscribe((response) => {
        if (!response || !this.modelNames.includes(response.model_name)) return;

        this.chartData = [];
        if (this.parentChartData[0]) {
          this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
        }

        // const modelData = { s: 'ok', t: [response.t], t_utc: [response.t_utc] };
        // modelData[response.model_name] = [+response.prediction];
        // this.onModelData(modelData);
      });

    } else {

      this._intervalId = window.setInterval(() => {
          this.chartData = [];
          if (this.parentChartData && this.parentChartData.length > 0) {
            this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
          }
        }, 2000);
    }
  }

  private init() {
    setTimeout(async () => {
      this._subs.add = this._settingsService.chartTraffic$.subscribe((response: any) => {
        this.initTraffic();
      });

      this._subs.add = this._widgetService.streamUpdate$.subscribe((response: any) => {
        if (response.parentId !== this.parentId) return;
        this.streamName = response.streamName;
        this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}`;
        this.chartData = [];

        if (this.parentChartData && this.parentChartData.length > 0)
          this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
      });

      this._stratsService.loadReports(this.teamName).subscribe((response) => {
        this.reports = <any[]>response;
      });

      this._stratsService.load(this.teamName).subscribe((response) => {
        this.strategies = <any[]>response;
        this.strats.forEach((strat) => {
          this.hiddenStrategyList.toggle(this.strategies.find((x) => x.name == strat));
        });
      });

      this._subs.add = this._widgetService.intervalUpdate$.subscribe((response: any) => {
        if (response.parentId !== this.parentId) return;
        this.interval = response.interval;
        this.initTraffic();
        this._widgetService.intervalUpdate$.next({ parentId: this.id, interval: this.interval });
      });

      this._subs.add = this._widgetService.tickerUpdate$.subscribe((response: any) => {
        if (response.parentId !== this.parentId) return;
        this.ticker = response.ticker;
        this.initTraffic();
        this._widgetService.tickerUpdate$.next({ parentId: this.id, ticker: this.ticker });
      });

      this._subs.add = this._widgetService.stratEdit$.subscribe((response) => {
        if (this.parentChartData && this.parentChartData.length > 0) {
          this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
        }
      });

      this._subs.add = this._widgetService.stratsUpdate$.subscribe((response) => {
        if (response.parentId !== this.id) return;
        this.strats = response.strats;
      });

      this._subs.add = this._widgetService.parentChartDataUpdate$.subscribe((response: any) => {
        if (response.parentId !== this.parentId) return;

        this.parentChartData = response.chartData;
      });

      this._subs.add = this._widgetService.loadModelData$.subscribe((response) => {
        if (response.parentId !== this.parentId) return;

        this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
      });

      this._widgetService.childAdd$.next({ id: this.id, parentId: this.parentId });
      this._subs.add = this._widgetService.parentXAxisUpdate$.subscribe((response) => {
        if (response.id !== this.parentId) return;
        debug("parentXAxisUpdate")
        const oldRange = this.modelChartXAxes.visibleRange;
        this.modelChartXAxes.visibleRange = response.visibleRange;
        if (response.visibleRange.min < oldRange.min) {
          // get more data
          this.loadModelData(Math.trunc(response.visibleRange.min), Math.trunc(response.visibleRange.max));
        }

      });

      this.dataFeedUrl = `${ConfigService.udfUrl}/${this.teamName}`;
      this.verticalGroup = new SciChartVerticalGroup();
      await this.initModelCanvas();

      this._subs.add = this.modelChartXAxes.visibleRangeChanged.subscribe((data1) => {
        this._widgetService.childXAxisUpdate$.next({ id: this.id, visibleRange: data1.visibleRange });
      });

      const modelsSub = this.modelIndicatorsService.load(this.teamName).subscribe(async (response) => {
        modelsSub.unsubscribe();
        this.models = response?.sort();
        if (this.modelName) {
          this.onModelSelect(this.modelName);
        } else if (this.models.length > 0) {
          this.onModelSelect(this.models[0]);
        }
      });

      this.initTraffic();
      this._settingsService.chartTheme$.subscribe(this.$changeTheme.bind(this));
      this._settingsService.theme$.subscribe(this.$changeTheme.bind(this));
    }, 100);
  }

  public async initModelCanvas() {
    const theme = this._settingsService.getChartThemeProvider();
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(this.id, {
      theme,
      title: this.title,
      disableAspect: true
    });

    this.modelWasmContext = wasmContext;
    this.modelChartSurface = sciChartSurface;

    this.modelChartXAxes = new DateTimeNumericAxis(wasmContext);
    this.modelChartXAxes.labelProvider.formatCursorLabel = (dataValue) => {
      return new Date(dataValue * 1000).toLocaleString();
    };

    sciChartSurface.xAxes.add(this.modelChartXAxes);
    sciChartSurface.yAxes.add(new NumericAxis(wasmContext, { labelPrecision: 5 }));

    sciChartSurface.yAxes.add(
      new NumericAxis(wasmContext, {
        id: 'value',
        growBy: new NumberRange(0, 4),
        isVisible: false,
        autoRange: EAutoRange.Always,
        labelPrecision: 0
      })
    );

    this.modelModifiers.dataPoint.allowDragSelect = false;

    const dataPointSelectionModifier = this.modelModifiers.dataPoint;
    dataPointSelectionModifier.allowDragSelect = false;
    (dataPointSelectionModifier.getSelectionMode = (modifierKeys, isAreaSelection) => {
      this.modifierKeys = modifierKeys;
      return ESelectionMode.Replace;
    }),
      dataPointSelectionModifier.selectionChanged.subscribe((args) => {
        if (args.selectedDataPoints.length > 1) {
          console.warn(`Multiple Data Points Selected: ${args.selectedDataPoints.length}`);
          return;
        }

        if (args.selectedDataPoints.length == 1) {
          const datapoint = args.selectedDataPoints[0];
          const transformedDate = new Date(this.modelxValues[datapoint.index] * 1000).toISOString();
          this.clickPredict(transformedDate, this.modelValues[datapoint.index]); // 2023-12-15T16:00:00+00:00
        }

        this.verticalGroup.addSurfaceToGroup(sciChartSurface);
      });

    sciChartSurface.chartModifiers.add(...Object.values(this.modelModifiers));

    this.verticalGroup.addSurfaceToGroup(sciChartSurface);
  }

  async clickStrategyCheckbox(event, strategy) {
    this.hiddenStrategyList.toggle(strategy);
    this._widgetService.loadModelData$.next({ parentId: this.id });
    this.strats = this.hiddenStrategyList.selected.map((x) => x.name);
    if (this.parentChartData && this.parentChartData.length > 0) {
      this.loadModelData(this.parentChartData[0].t, this.parentChartData[this.parentChartData.length - 1].t);
    }
  }

  async editStrategy(event, strategy) {
    this.onStrategyEditor.emit({ parentId: this.id, modelName: this.modelName, name: strategy.name });
  }

  public changes = [];
  public showInputs = false;
  public inputsLoading = false;
  public inputData: any = null;
  public features: string[] = null;

  public clickPredict(timestamp, modelValues) {
    if (!this.modifierKeys.shiftKey) {
      return;
    }

    this.onPredictSelect.emit({ parentId: this.parentId, timestamp, modelValues, modelName: this.modelName });
  }

  public onModelSelect(modelNames) {
    if (modelNames) {
      this.modelName = Array.isArray(modelNames) ? modelNames[0] : modelNames;
      localStorage.setItem('lastSelectedModel', this.modelName);
    }

    this.modelxValues = [];
    this.modelValues = [];
    this.modelData = [];
    this.annotations = [];
    this.firstTime = true;

    this.reloadChart();
  }

  public reloadChart() {
    let from, to;
    if (this.chartData.length > 0) {
      from = this.chartData[0].t;
      to = this.chartData[this.chartData.length - 1].t;
    } else {
      to = Math.floor(new Date().getTime() / 1000);
      from = to - this.graphIncrement;
    }

    this.loadModelData(from, to);
  }

  lastFrom = null;
  lastTo = null;
  startTimestamp = "";
  endTimestamp = "";
  startIsLoading = false;
  endLoading = false;
  calls = 0;
  returns = 0;
  @debounce(500)
  loadModelData(from, to) {
    if (!this.modelName) {
      this.modelChartSurface.renderableSeries.clear();
      return;
    }

    let dataFeedUrl = this.dataFeedUrl;
    this.calls++;

    if(!this.lastFrom || from < this.lastFrom) {
      this.startIsLoading = true;
    }
    this.lastFrom = from;
    if(!this.lastTo || to > this.lastTo) {
      this.endLoading = true;
    }
    this.lastTo = to;
    debug("REQUEST", formatDate(from), formatDate(to))

    var sub2 = this.streamsService
      .getModelData(dataFeedUrl, this.modelNames.join(","), this.interval, from, to, this.ensembleMethod, this.strats)
      .subscribe((response) => {
        sub2.unsubscribe();
        this.returns++;
        if ((<any>response).s == 'no_data') {
          const nextTime = (<any>response).nextTime;
          if(nextTime) {
            // do something intelligent with nextTime
            setTimeout(() => {
              const to = new Date(parseInt(nextTime)).getTime();
              if (to) {
                const from = to - this.graphIncrement;
                this.loadModelData(from, to);
              }
              return;
            }, 100);
          }
          else {
            this.startIsLoading = false;
            this.endLoading = false;
          }
        } else {
          this.onModelData(response);
        }
      });
  }

  onModelData(response) {

    if(this.calls == this.returns) {
      this.startIsLoading = false;
      this.endLoading = false;
    }

    const timestamps = (<any>response).t;
    const start = timestamps[0];
    const end = timestamps[timestamps.length - 1];
    debug("RECEIVE", formatDate(start), formatDate(end));

    let thisData = [];
    for (var i = 0; i < (<any>response).t.length; i++) {

      const yValue = response[this.ensembleMethod][i] ?? 0;

      let thisObj = {
        t: (<any>response).t[i],
        c: yValue
      };
      this.strats.forEach((x) => {
        if (Object.keys(response).includes(x)) {
          thisObj[x] = (<any>response)[x][i];
        }
      });
      const foundIndex = this.modelData.findIndex((x) => x.t == thisObj.t);
      if (foundIndex != -1) {
        this.strats.forEach((strategy) => {
          if (
            Object.keys(response).includes(strategy) &&
            (!Object.keys(this.modelData[foundIndex]).includes(strategy) ||
              this.modelData[foundIndex][strategy] !== (<any>response)[strategy][i])
          ) {
            this.modelData[foundIndex][strategy] = (<any>response)[strategy][i];
          }
        });
      } else {
        thisData.push(thisObj);
      }
    }
    if (thisData.length > 0) {
      (<any>window).thisData = thisData;
    }

    var combined = this.modelData.concat(...thisData);
    combined.sort((a, b) => a.t - b.t);
    var distinct = combined.filter((value, index, array) => {
      return array.find((x) => x.t == value.t) == value;
    });
    this.modelData = distinct;

    this.startTimestamp = formatDate(this.modelData[0].t)
    this.endTimestamp = formatDate(this.modelData[this.modelData.length - 1].t);

    this.modelxValues = this.modelData.map((x) => x.t);
    this.modelValues = this.modelData.map((x) => x.c);

    // Create a OhlcDataSeries with open, high, low, close values
    const dataSeries = new XyDataSeries(this.modelWasmContext, {
      dataIsSortedInX: true,
      containsNaN: false,
      xValues: this.modelxValues,
      yValues: this.modelValues
    });

    // Create and add the Candlestick series
    const lineSeries = new FastLineRenderableSeries(this.modelWasmContext, {
      strokeThickness: 1,
      dataSeries,
      stroke: this.seriesColor // Used for legend template
    });

    const alertNames = this.alerts.map((x) => x.value);
    const oldAnnotationCount = alertNames.map((x) => this.annotations.filter((y) => y).filter((y) => y.split(':')[2] == x).length);

    this.annotations = new Array(this.modelData.length).fill(null);
    this.strats.forEach((x) => {
      for (let i = 0; i < this.modelData.length; i++) {
        if (this.modelData[i][x]) {
          this.annotations[i] = this.modelData[i][x];
        }
      }
    });

    let annotationMap = [];
    for (let index = 0; index < this.annotations.length; index++) {
      const annotation = this.annotations[index];
      if (annotation) {
        annotationMap.push({
          timestamp: this.modelxValues[index],
          annotation: annotation
        });
      }
    }
    if (JSON.stringify(annotationMap) !== this.lastAnnotationMap) {
      this._widgetService.annotationsUpdate$.next({ parentId: this.parentId, myId: this.id, annotationMap: annotationMap });
      this.lastAnnotationMap = JSON.stringify(annotationMap);
    }

    const newAnnotationCount = alertNames.map((x) => this.annotations.filter((y) => y).filter((y) => y.split(':')[2] == x).length);
    for (let i = 0; i < newAnnotationCount.length; i++) {
      if (newAnnotationCount[i] > 0 && newAnnotationCount[i] != oldAnnotationCount[i] && !this.firstTime) {
        this.playNotificationSound(alertNames[i]);
      }
    }
    this.firstTime = false;

    this.addAnnotations(this.annotations);

    let exception = (<any>response).exception;
    if (exception !== this.last_exception && exception) {
      console.error(exception);
      this.openSnackBar(exception, 'Error');
    }
    this.last_exception = exception;

    this.modelChartSurface.renderableSeries.clear();
    this.modelChartSurface.renderableSeries.add(lineSeries);

    if (this.old_model !== this.modelName) {
      this.modelChartSurface.zoomExtents();
      this.old_model = this.modelName;
    }
  }

  playNotificationSound(name) {
    let audio = new Audio();
    audio.src = `/assets/audio/${name}.mp3`;
    audio.load();
    audio.play();
  }

  public addAnnotations(annotations) {
    const buyMarkerAnnotation = (x1: number, y1: number, color: string): CustomAnnotation => {
      if (!color) {
        color = appTheme.VividGreen;
      }
      return new CustomAnnotation({
        x1,
        y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Top,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        svgString: `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(-54.867218,-75.091687)">
                      <path style="fill:${color};fill-opacity:0.77;stroke:${color};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                          d="m 55.47431,83.481251 c 7.158904,-7.408333 7.158904,-7.408333 7.158904,-7.408333 l 7.158906,7.408333 H 66.212668 V 94.593756 H 59.053761 V 83.481251 Z"
                      "/>
                  </g>
              </svg>`
      });
    };

    // Returns a CustomAnnotation that represents a sell marker arrow
    // The CustomAnnotation supports SVG as content. Using Inkscape or similar you can create SVG content for annotations
    const sellMarkerAnnotation = (x1: number, y1: number, color: string): CustomAnnotation => {
      if (!color) {
        color = appTheme.VividRed;
      }
      return new CustomAnnotation({
        x1,
        y1,
        verticalAnchorPoint: EVerticalAnchorPoint.Bottom,
        horizontalAnchorPoint: EHorizontalAnchorPoint.Center,
        svgString: `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-54.616083,-75.548914)">
                        <path style="fill:${color};fill-opacity:0.77;stroke:${color};stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
                        d="m 55.47431,87.025547 c 7.158904,7.408333 7.158904,7.408333 7.158904,7.408333 L 69.79212,87.025547 H 66.212668 V 75.913042 h -7.158907 v 11.112505 z"
                        />
                    </g>
                </svg>`
      });
    };

    this.modelChartSurface.annotations.clear();
    for (let i = 0; i < annotations.length; i++) {
      if (this.modelxValues.length > i) {
        if (!annotations[i]) {
          continue;
        }
        const [annotation, color] = annotations[i].split(':');
        if (annotation == 'up') {
          this.modelChartSurface.annotations.add(buyMarkerAnnotation(this.modelxValues[i], this.modelValues[i], color));
        }
        if (annotation == 'down') {
          this.modelChartSurface.annotations.add(sellMarkerAnnotation(this.modelxValues[i], this.modelValues[i], color));
        }
      }
    }
  }

  public openSnackBar(message: string, action: string): void {
    const durationInSeconds = 3;
    const horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    const verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    this._snackbar.open(message, action, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: durationInSeconds * 1000
    });
  }

  public close() {
    this._widgetService.childDelete$.next({ id: this.id, parentId: this.parentId });
    super.close();
  }

  public $changeTheme(): void {
    if (this.modelChartSurface) this.modelChartSurface.applyTheme(this._settingsService.getChartThemeProvider());
  }
}
