import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-widget-titlebar',
  templateUrl: './widget-titlebar.component.html',
  styleUrls: ['./widget-titlebar.component.scss', '_widget-titlebar-theme.scss']
})
export class WidgetTitlebarComponent {
  @HostBinding('class.code') @Input() code: boolean = false;
  @Input() restorable: boolean = true;
  @Input() title: string = null;
  @Input() isMaximized: boolean = false;

  @Output() onClose = new EventEmitter<void>();
  @Output() onRestore = new EventEmitter<void>();
  @Output() onMaximize = new EventEmitter<void>();

  public get isDesktopEnvironment(): boolean {
    return this._settings.desktopEnvironment;
  }

  constructor(private readonly _settings: SettingsService) {}
}
