// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100dvh;
  width: 100dvw;
}
:host.menubar-top {
  flex-direction: column;
}
:host.menubar-left {
  flex-direction: row;
}

.content {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/chat-page/chat-page.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,sBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,YAAA;AADF","sourcesContent":[":host {\n  display: flex;\n  height: 100dvh;\n  width: 100dvw;\n\n  &.menubar-top {\n    flex-direction: column;\n  }\n\n  &.menubar-left {\n    flex-direction: row;\n  }\n}\n\n.content {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
