import { Component, Input } from '@angular/core';
import { Node } from 'src/app/models/workflow-node';

@Component({
  selector: '[node-title]',
  template: `<svg:rect
      [attr.x]="labelX"
      [attr.y]="labelY"
      [attr.rx]="radius"
      [attr.ry]="radius"
      [attr.width]="labelWidth"
      [attr.height]="labelHeight"
      [class]="labelClass"
    />
    <svg:text [attr.x]="x" [attr.y]="y" [class]="textClass" [attr.font-size]="fontSize" text-anchor="middle">
      {{ displayName }}
    </svg:text>`,
  styleUrls: ['./workflow-canvas-node.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasNodeTitleComponent {
  @Input() node: Node;
  @Input() unselected: boolean;

  public readonly x = 0;
  public readonly labelHeight = 18;
  public readonly radius = 10;

  private get trueName(): string {
    return ['START', 'END'].includes(this.node.id) ? this.node.id.toLowerCase() : this.node.name;
  }

  public get y(): number {
    return 58 * this.node.scale;
  }

  public get fontSize(): number {
    return 12 * this.node.scale;
  }

  public get labelClass(): string {
    return 'label' + (this.unselected ? ' unselected' : '');
  }

  public get textClass(): string {
    return 'font-mono title' + (this.unselected ? ' unselected' : '');
  }

  public get displayName(): string {
    const namedByFirstParameter = [ 'input', 'llm' ];
    return namedByFirstParameter.includes(this.trueName.toLowerCase()) && this.node.parameters.length && this.node.parameters[0].value.length
      ? this.node.parameters[0].value
      : this.trueName;
  }

  public get labelWidth(): number {
    return this.displayName.length * 7 + 30;
  }

  public get labelX(): number {
    return this.x - this.labelWidth / 2;
  }

  public get labelY(): number {
    return this.y - 13;
  }
}
