import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Asset } from 'src/app/models/asset';
import { Definition } from 'src/app/models/definition';
import { Meta } from 'src/app/models/meta';
import { Project } from 'src/app/models/project';
import { Workflow } from 'src/app/models/workflow';
import { AssetsService } from 'src/app/services/assets.service';
import { DefinitionsService } from 'src/app/services/definitions.service';
import { ExperimentsService } from 'src/app/services/experiments.service';
import { GpusService } from 'src/app/services/gpus.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-model-selector',
  templateUrl: './model-selector.component.html',
  styleUrls: ['./model-selector.component.scss'],
  providers: [ WorkflowService ]
})
export class ModelSelectorComponent implements OnInit, OnChanges {
  @ViewChild(DynamicFormComponent) componentForm: DynamicFormComponent;

  @Input() teamName: string;
  @Input() project: Project;
  @Output() onDefinitionClick = new EventEmitter<{ projectName: string; definitionName: string }>();

  public selectedNeuralNet: Definition = null;
  public newDefinitionMarker: string = '__NEW__DEFINITION__';
  public neuralnets: Definition[] = [];

  public definition = null;
  public neuralnetcomponents = [];
  public selectedNeuralNetComponent = null;
  public selectedNeuralNetComponentParameter = null;

  public devices: string[] = ['cpu'];
  public allGPUS = [];
  public selectedDevice: any = null;

  public meta: Meta = null;
  private workflow: Workflow;
  private assets: any[] = [];
  public dynamicFormData: any;
  public saveStatus: 'COMPLETE' | 'ERROR' | 'SAVING' | 'SAVED' = 'COMPLETE';
  public lastSaveTime: string = null;
  public get workflowComponents(): any[] {
    return this.definition?.workflow?.nodes;
  }

  constructor(
    private _definitionsService: DefinitionsService,
    private _gpuService: GpusService,
    private _experimentsService: ExperimentsService,
    private _assetsService: AssetsService,
    private _workflowService: WorkflowService
  ) {
    (<any>window).modelSelector = this;
  }

  ngOnInit(): void {
    this.init();
  }

  async ngOnChanges(_changes: SimpleChanges): Promise<void> {
    if (_changes.project.firstChange || _changes.project.currentValue?.name === _changes.project.previousValue?.name) return;

    this.init();
  }

  init() {
    const sub = this._definitionsService.load(this.teamName).subscribe((response: any) => {
      sub.unsubscribe();
      this.neuralnets = response;
      this.neuralnets.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

    const gpusub = this._gpuService.countGpus(this.teamName).subscribe((response) => {
      gpusub.unsubscribe();
      this.allGPUS = response;
      this.devices = ['cpu'];
      if (this.allGPUS.length > 0) {
        const count = this.allGPUS[0]['count'];
        for (let i = 0; i < count; i++) {
          this.devices.push(`gpu${i}`);
        }
      }
    });

    const assetSub = this._assetsService.load(this.teamName).subscribe((response: Asset[]) => {
      assetSub.unsubscribe();
      if (!response || response.length === 0) return;

      this.assets = response.filter((x) => x.numProdFiles > 0);
    });
  }

  getDescriptor(component) {
    var retval = component.name;
    if (component.name == 'input') {
      retval = `input (${component.parameters.find((x) => x.name == 'feature')?.value})`;
    }
    var componentName = component.parameters.find((x) => x.name == 'component_name');
    if (componentName && componentName?.value) {
      retval = componentName.value;
    }
    return retval;
  }

  selectNeuralNet($event) {
    if ($event.value == this.newDefinitionMarker) {
      this.selectedNeuralNet = null;
      this.onDefinitionClick.emit({
        projectName: this.project.name,
        definitionName: 'add'
      });
    } else {
      this.selectedNeuralNet = $event.value;
      const defSub = this._definitionsService.get(this.teamName, this.selectedNeuralNet.name).subscribe((response) => {
        defSub.unsubscribe();
        this.definition = response;
        this._workflowService.load(this.definition.workflow);

        this.definition?.availableComponents.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
    }
  }

  openNeuralNet() {
    this.onDefinitionClick.emit({ projectName: this.project.name, definitionName: this.selectedNeuralNet.name });
  }

  selectComponent($event) {
    this.dynamicFormData = null;
    this.meta = null;

    this.selectedNeuralNetComponent = this.definition.availableComponents.find((x) => x.name == $event.value);
    this.selectedNeuralNetComponent.parameters = this.selectedNeuralNetComponent.parameters.filter((x) => x.name.toLowerCase() !== 'icon');
    this.selectedNeuralNetComponent.parameters.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  selectComponentParameter($event) {
    this.selectedNeuralNetComponentParameter = $event.value;
    const param = this.selectedNeuralNetComponentParameter;
    this.meta = { params: [], returns: null };

    let options = [];
    if (param.type === 'select') {
      options = param.options;
    }

    this.meta.params.push({
      name: param.name,
      description: param.description,
      type: param.type,
      required: false,
      options: this.filterOptions(param.name, options)
    });

    const component = this.selectedNeuralNetComponent;
    const node = this.definition.workflow.nodes.find((x) => x.name === component.name);
    const nodeParam = node.parameters.find((x) => x.name === param.name);

    this.dynamicFormData = { id: Math.random().toString(36).substring(2, 9) };
    this.dynamicFormData[param.name] = nodeParam.value;
  }

  filterOptions(name, options) {
    if (name !== 'feature') {
      return options;
    }

    const asset = this.assets.find((asset) => asset.name === this.workflow?.assets[0]);
    if (!asset || !asset.features) {
      return [];
    }

    return asset.features;
  }

  async onSaved() {
    this.saveStatus = 'SAVING';
    const param = this.selectedNeuralNetComponentParameter;
    const data = this.componentForm.getFormData();
    param.value = data[param.name];

    const component = this.selectedNeuralNetComponent;
    const node = this.definition.workflow.nodes.find((x) => x.name === component.name);
    const nodeParam = node.parameters.find((x) => x.name === param.name);
    nodeParam.value = param.value;
    await this._definitionsService.updateAsync(this.teamName, this._workflowService.export(), this.definition.workflow.name);
    this.saveStatus = 'SAVED';
    this.lastSaveTime = new Date().toLocaleString();

    setTimeout(() => {
      this.saveStatus = 'COMPLETE';
    }, 2000);
  }

  async launchExperiment(smartfit?: boolean) {
    const sub = this._experimentsService.start(this.teamName, this.selectedNeuralNet.name, this.selectedDevice, smartfit).subscribe(() => {
      sub.unsubscribe();
    });
  }
}
