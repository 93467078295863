import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TeamsMainComponent } from './components/teams/main/main.component';
import { AuthGuard } from './services/auth.guard';
import { ExperimentsComponent } from './components/models/experiments/experiments.component';
import { HostsComponent } from './components/devices/hosts/hosts.component';
import { GpusComponent } from './components/devices/gpus/gpus.component';
import { VaultComponent } from './components/models/vault/vault.component';
import { HostComponent } from './components/devices/host/host.component';
import { DiscoveryComponent } from './components/data/discovery/discovery.component';
import { ProjectsComponent } from './components/team/projects/projects.component';
import { ProjectComponent } from './components/team/project/project.component';
import { ModelViewComponent } from './components/models/model-view/model-view.component';
import { ModelDiffComponent } from './components/models/model-diff/model-diff.component';
import { VaultDetailComponent } from './components/models/vault-detail/vault-detail.component';
import { FeaturesComponent } from './components/data/features/features.component';
import { SchemasComponent } from './components/data/schemas/schemas.component';
import { FeatureComponent } from './components/data/feature/feature.component';
import { HomeComponent } from './components/home/home.component';
import { SchemaComponent } from './components/data/schema/schema.component';
import { PeopleComponent } from './components/team/people/people.component';
import { PersonComponent } from './components/team/person/person.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { BuildsComponent } from './components/data/builds/builds.component';
import { AssetsComponent } from './components/data/assets/assets.component';
import { AssetComponent } from './components/data/asset/asset.component';
import { BuildComponent } from './components/data/build/build.component';
import { RawComponent } from './components/data/raw/raw.component';
import { DeployComponent } from './components/models/deploy/deploy.component';
import { DeploysComponent } from './components/models/deploys/deploys.component';
import { TvChartContainerComponent } from './components/tv-chart-container/tv-chart-container.component';
import { DefinitionsComponent } from './components/models/definitions/definitions.component';
import { DefinitionComponent } from './components/models/definition/definition.component';
import { InspectComponent } from './components/data/inspect/inspect.component';
import { StreamsComponent } from './components/models/streams/streams.component';
import { ChartComponent } from './components/models/chart/chart.component';
import { StreamComponent } from './components/models/stream/stream.component';
import { NewFeatureComponent } from './components/data/new-feature/new-feature.component';
import { DataViewerComponent } from './components/data/data-viewer/data-viewer.component';
import { ExploreComponent } from './components/data/explore/explore.component';
import { ProjectBoardComponent } from './components/team/project-board/project-board.component';
import { AnalysisContainerComponent } from './components/models/analysis-container/analysis-container.component';
import { GridPageComponent } from './components/pages/grid-page/grid-page.component';
import { InitComponent } from './components/init/init.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ChatPageComponent } from './components/pages/chat-page/chat-page.component';

export class RouterService {
  public static get routes() {
    return [
      {
        path: ':team/vault-detail/:vaultName',
        search: 'vault-detail',
        parent: ['vault'],
        component: VaultDetailComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model-diff/:left/:right',
        search: 'model-diff',
        parent: ['home'],
        component: ModelDiffComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model-view/:host/:path',
        search: 'model-view',
        parent: ['home'],
        component: ModelViewComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/vault', search: 'vault', parent: ['vault'], component: VaultComponent, canActivate: [AuthGuard] },
      {
        path: ':team/model/analysis',
        search: 'analysis',
        parent: ['analysis'],
        component: AnalysisContainerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/experiments',
        search: 'experiments',
        parent: ['experiments'],
        component: ExperimentsComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/hosts', search: 'hosts', parent: ['hosts'], component: HostsComponent, canActivate: [AuthGuard] },
      { path: ':team/hosts/:hostname', search: 'hosts', parent: ['hosts'], component: HostComponent, canActivate: [AuthGuard] },
      { path: ':team/gpus', search: 'gpus', parent: ['gpus'], component: GpusComponent, canActivate: [AuthGuard] },
      { path: ':team/home', search: 'home', parent: ['home'], component: HomeComponent, canActivate: [AuthGuard] },
      {
        path: ':team/data/features',
        search: 'features',
        parent: ['data', 'features'],
        component: FeaturesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/feature/:name',
        search: 'feature',
        parent: ['data', 'features'],
        component: FeatureComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/new-feature',
        search: 'new-feature',
        parent: ['data', 'features'],
        component: NewFeatureComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/explore/:project',
        search: 'features',
        parent: ['data', 'features'],
        component: ExploreComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/explore',
        search: 'features',
        parent: ['data', 'features'],
        component: ExploreComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/data/schemas', search: 'schemas', parent: ['data', 'schemas'], component: SchemasComponent, canActivate: [AuthGuard] },
      {
        path: ':team/data/schema/:rds',
        search: 'schema',
        parent: ['data', 'schemas'],
        component: SchemaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/schema/add',
        search: 'schema',
        parent: ['data', 'schemas'],
        component: SchemaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/data/schema/add/:project',
        search: 'schema',
        parent: ['data', 'schemas'],
        component: SchemaComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/data/builds', search: 'builds', parent: ['data', 'builds'], component: BuildsComponent, canActivate: [AuthGuard] },
      {
        path: ':team/data/build/:rds/:runid/:username/:isArchived',
        search: 'build',
        parent: ['data', 'builds'],
        component: BuildComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/data/assets', search: 'assets', parent: ['data', 'assets'], component: AssetsComponent, canActivate: [AuthGuard] },
      { path: ':team/data/raw', search: 'raw', parent: ['data', 'raw'], component: RawComponent, canActivate: [AuthGuard] },
      {
        path: ':team/data/data-viewer/:path',
        search: 'data-viewer',
        parent: ['data', 'raw'],
        component: DataViewerComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/data/asset/:name', search: 'asset', parent: ['data', 'assets'], component: AssetComponent, canActivate: [AuthGuard] },
      {
        path: ':team/data/inspect/:type/:name/:file',
        search: 'inspect',
        parent: ['home'],
        component: InspectComponent,
        canActivate: [AuthGuard]
      },
      { path: ':team/discovery', search: 'discovery', parent: ['discovery'], component: DiscoveryComponent, canActivate: [AuthGuard] },
      { path: ':team/projects', search: 'projects', parent: ['projects'], component: ProjectsComponent, canActivate: [AuthGuard] },
      { path: ':team/project/:project', search: 'project', parent: ['projects'], component: ProjectComponent, canActivate: [AuthGuard] },
      { path: ':team/project-board', search: 'project', parent: ['projects'], component: ProjectBoardComponent, canActivate: [AuthGuard] },
      { path: ':team/people', search: 'people', parent: ['people'], component: PeopleComponent, canActivate: [AuthGuard] },
      { path: ':team/person/:username', search: 'person', parent: ['people'], component: PersonComponent, canActivate: [AuthGuard] },
      { path: ':team/terminal', search: 'terminal', parent: ['hosts'], component: TerminalComponent, canActivate: [AuthGuard] },
      { path: ':team/deploys', search: 'deploys', parent: ['deploys'], component: DeploysComponent, canActivate: [AuthGuard] },
      { path: ':team/chart', search: 'chart', parent: ['charts'], component: TvChartContainerComponent, canActivate: [AuthGuard] },
      { path: ':team/deploy/:name', search: 'deploy', parent: ['deploys'], component: DeployComponent, canActivate: [AuthGuard] },
      {
        path: ':team/model/definitions',
        search: 'definitions',
        parent: ['model', 'definitions'],
        component: DefinitionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/definition/:rds',
        search: 'definition',
        parent: ['model', 'definitions'],
        component: DefinitionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/streams',
        search: 'streams',
        parent: ['model', 'streams'],
        component: StreamsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/chart/:streamName',
        search: 'chart',
        parent: ['model', 'charts'],
        component: ChartComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/strategy',
        search: 'chart',
        parent: ['model', 'charts'],
        component: GridPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/stream/add',
        search: 'chart-settings',
        parent: ['model', 'charts'],
        component: StreamComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':team/model/stream/:streamName',
        search: 'chart-settings',
        parent: ['model', 'charts'],
        component: StreamComponent,
        canActivate: [AuthGuard]
      },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
      { path: 'teams', component: TeamsMainComponent, search: 'teams', parent: ['teams'], canActivate: [AuthGuard] },
      { path: ':team/grid', component: GridPageComponent, search: '', parent: [], canActivate: [AuthGuard] },
      { path: ':team/chat', component: ChatPageComponent, search: '', parent: [], canActivate: [AuthGuard] },
      { path: '', component: TeamsMainComponent, canActivate: [AuthGuard] },
      { path: 'init', component: InitComponent, canActivate: [AuthGuard] }
    ];
  }

  public static get prefixes() {
    return ['data', 'model'];
  }

  public static getParent(pathToSearch: string) {
    //remove team name
    let parts = pathToSearch.split('/').slice(2);

    if (this.prefixes.includes(parts[0])) parts = pathToSearch.split('/').slice(3);

    const route = this.routes.find((x) => x.search === parts[0]);
    if (!route) return ['teams'];

    return route.parent;
  }
}
@NgModule({
  imports: [RouterModule.forRoot(RouterService.routes, {})], //, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
