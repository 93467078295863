import { Component, HostBinding } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { WidgetService } from 'src/app/services/widget.service';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss']
})
export class ChatPageComponent {
  @HostBinding('class.menubar-top')
  private get _menubarTop() {
    return this.menubarPosition === 'top';
  }

  @HostBinding('class.menubar-left')
  private get _menubarLeft() {
    return this.menubarPosition === 'left';
  }

  public openWidget?: WidgetService.SplitViewWidget;

  public get menubarPosition() {
    return this._settings.menubarPosition;
  }

  private _addWidget(widgetName: string): void {
    this.openWidget = {
      component: WidgetService.REGISTRY[widgetName],
      inputs: {
        teamName: this.teamName
      },
      outputs: {
        onClose: () => (this.openWidget = undefined)
      }
    };
  }

  public get teamName(): string {
    return this._identity.me.selectedTeamName;
  }

  constructor(
    private readonly _settings: SettingsService,
    private readonly _identity: IdentityService
  ) {}

  public $openWidget(widgetName: WidgetService.WidgetName): void {
    this._addWidget(widgetName);
  }
}
