import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ChatService } from 'src/app/services/chat.service';
import { IdentityService } from 'src/app/services/identity.service';
import { ConfigService } from 'src/app/services/config.service';
import { isNil } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { debounce } from 'src/app/directives/debounce.decorator';
import { SocketService } from 'src/app/services/socket.service';
import { ChatScreenComponent } from '../../chat-screen/chat-screen.component';
import { WidgetService } from 'src/app/services/widget.service';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SettingsService } from 'src/app/services/settings.service';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const cutMillisFromTimestamp = (value: Date | number) => Math.trunc((value instanceof Date ? value.getTime() : value) / 1000);

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './chat-widget.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'model-selector-overlay-pane' }
    }
  ]
})
export class ChatWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('chat', { static: true }) chatScreen: ChatScreenComponent;
  @ViewChild('chatInput') chatInput!: ElementRef<HTMLInputElement>;

  @Input() uuid: string | undefined;
  @Input() title: string | undefined;
  @Input() model: string = 'deepseek-r1:32b';

  @Output() onViewExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onViewDefinition = new EventEmitter<{ projectName: string; definitionName: string }>();
  @Output() onViewBuild = new EventEmitter<{ projectName: string; runid: number }>();
  @Output() onViewAsset = new EventEmitter<{ projectName: string; assetName: string }>();
  @Output() onViewFeatureExplorer = new EventEmitter<{ projectName: string }>();
  @Output() onViewVault = new EventEmitter<{ projectName: string }>();
  @Output() onViewUpload = new EventEmitter();
  @Output() modelChange = new EventEmitter<string>();

  private _ticker: string | undefined;
  private _rawSessions: ChatService.ChatSession[] = [];

  public messages: ChatService.ChatMessage[] = [];
  public disabled: boolean = false;
  public historyOpen: boolean = true;
  public input: string = null;
  public loading = false;
  public availableModels: ChatService.AvailableModel[] = [];
  public modelName: string = 'Deepseek-R1-32B';
  public pullStatus: string = 'Making sure you have the latest models';
  public pullProgress: number = 0;
  public pullProgressBuffer: number = 0;
  public hasAllModels: boolean = false;
  public anchors: string[] = [];
  public sessions: [string, ChatService.ChatSession[]][] = [];

  public get submitDisabled() {
    return !this.input || this.input.trim().length === 0 || this.loading || !this.hasAllModels;
  }

  public get selfTurns() {
    return this.messages.filter(({ isSelf }) => isSelf);
  }

  public get isDesktopEnvironment(): boolean {
    return this._settings.desktopEnvironment;
  }

  public get username(): string {
    return this._identity.me.username;
  }

  public get llmName(): string {
    return `${this._ticker.toLocaleUpperCase()}.llm`;
  }

  constructor(
    private readonly _chat: ChatService,
    private readonly _identity: IdentityService,
    private readonly _socket: SocketService,
    private readonly _widget: WidgetService,
    private readonly _settings: SettingsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit() {
    if ((<any>window).chat) (<any>window).chat.push(this);
    else (<any>window).chat = [this];

    if (this.uuid === undefined) {
      this._chat.getNewUUIDAsync().then(({ uuid }) => {
        this.uuid = uuid;
      });
    }

    this.update_sessions();

    this._socket.joinRoom('chat');
    this._subs.add = this._socket.subscribeToRoomEvents('chat', (data: any) => {
      if (data.origin === 'experiment.change') {
        this.messages.push({ isSelf: false, content: data.msg });
      } else if (data.origin === 'pull.update') {
        const { progress } = data;
        this.pullProgress = progress.total * 100;
        this.pullProgressBuffer = progress.buffer * 100;
      } else if (data.origin === 'pull.complete') {
        const { failed } = data;

        if (failed.length !== 0) {
          this.pullStatus = 'Failed to pull one or more models';
          console.warn('failed to pull', failed);
        } else {
          this.hasAllModels = true;
        }

        this.update_sessions();
      }
    });

    this._socket.joinRoom('llm');
    this._subs.add = this._socket.subscribeToRoomEvents('llm', (data: any) => {
      if (data.team == this.teamName && data.origin === 'udf' && data.definition == this.llmName) {
        this.handleIncomingMessage(data);
      }
    });

    this._chat
      .modelsAsync()
      .then(({ revisors, hasAllModels }) => {
        this.availableModels = revisors;
        this.hasAllModels = hasAllModels;
      })
      .catch(console.warn);

    this.getHistory();
  }

  public ngOnDestroy(): void {
    this._socket.leaveRoom('chat');
    super.ngOnDestroy();
  }

  @debounce(100)
  private _scrollToBottom(): void {
    this.chatScreen.scrollToBottom();
  }

  @debounce(100)
  private _focusPromptBox(): void {
    setTimeout(this.chatInput.nativeElement.focus.bind(this.chatInput.nativeElement), 0);
  }

  private organizeChatSessions(sessions?: ChatService.ChatSession[]): [string, ChatService.ChatSession[]][] {
    sessions ??= this._rawSessions;
    const today = (() => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      return today.getTime();
    })();

    const getTimeframe = ({ mtime }: ChatService.ChatSession): string => {
      if (mtime >= today) return 'Today';
      else if (mtime >= today - MS_PER_DAY) return 'Yesterday';
      else if (mtime >= today - 7 * MS_PER_DAY) return 'Previous 7 Days';
      else if (mtime >= today - 30 * MS_PER_DAY) return 'Previous 30 Days';
      return new Date(mtime).getFullYear().toString();
    };

    const organizedSessions: typeof this.sessions = [];
    const seenTimeframes: Set<string> = new Set();
    sessions
      .sort((a, b) => b.mtime - a.mtime)
      .forEach((session) => {
        const timeframe = getTimeframe(session);
        if (seenTimeframes.has(timeframe)) {
          organizedSessions[organizedSessions.length - 1][1].push(session);
        } else {
          organizedSessions.push([timeframe, [session]]);
          seenTimeframes.add(timeframe);
        }
      });

    return organizedSessions;
  }

  private handleIncomingMessage(data: any) {
    if (!data || typeof data !== 'object') return;

    // Handle cached analysis retrieval
    if (data.s === 'ok' && data.components) {
      console.log('Processing cached analysis...');

      // Handle system prompt if it exists
      if (data.system_prompt) {
        let systemPromptName = 'System Prompt';
        this.messages.push({
          isSelf: true,
          title: systemPromptName,
          isAnchor: true,
          content: data.system_prompt
        });
        this.anchors.push(systemPromptName);
      }

      // Iterate through cached component analyses
      Object.entries(data.components).forEach(([promptName, component]: [string, any]) => {
        if (component.prompt && component.response) {
          // Store anchor for navigation
          this.anchors.push(promptName);

          // Emit user prompt as an anchor message
          this.messages.push({
            isSelf: true,
            title: promptName,
            content: component.prompt,
            isAnchor: true
          });

          // Emit the corresponding LLM response
          this.messages.push({
            isSelf: false,
            content: component.response,
            isAnchor: false
          });
        }
      });

      // Emit the final analysis if it exists
      if (data.final_prompt) {
        this.messages.push({
          isSelf: true,
          content: data.final_prompt,
          isFinal: false
        });
      }
      if (data.final_analysis) {
        this.messages.push({
          isSelf: false,
          content: data.final_analysis,
          isFinal: true
        });
      }

      // End message when loading from cache
      this.messages.push({
        isSelf: false,
        content: `Cached analysis loaded. Last modified: ${data.last_modified_time}. Run /pull to regenerate.`,
        isAnchor: false
      });

      this.cdr.detectChanges();
      this._scrollToBottom();
      return; // Stop processing further since we handled cached data
    }

    // Handle live processing hints (e.g., "Thinking about X...")
    if (data.content && data.content.startsWith('Thinking about')) {
      this.messages.push({
        isSelf: false,
        content: data.content,
        isHint: true
      });
    } else {
      if (data.isAnchor) {
        this.anchors.push(data.title); // Ensure anchors are tracked for live messages
      }

      // Remove any related "Thinking about" hint message
      const index = this.messages.findIndex((m) => m.isHint && data.title && m.content.includes(data.title));
      if (index !== -1) {
        this.messages.splice(index, 1);
      }

      // Add the actual message
      this.messages.push({
        isSelf: data.isSelf,
        title: data.title ?? null,
        content: data.content,
        isAnchor: data.isAnchor ?? false,
        isFinal: data.isFinal ?? false,
        timestamp: data.timestamp ?? new Date().toISOString(),
      });
    }

    // Append "Analysis complete" message if final
    if (data.isFinal) {
      this.messages.push({
        isSelf: false,
        content: 'Analysis complete. Run /pull to regenerate.',
        isAnchor: false
      });
    }

    this.cdr.detectChanges();
    this._scrollToBottom();
  }

  public async getHistory(): Promise<void> {
    if (!ConfigService.chatUrl) {
        console.error('ChatUrl not found.');
        return;
    }
    if (this.uuid === undefined) return;

    const sub = this._chat.history(this.teamName, this._identity.me.username, this.uuid).subscribe((response) => {
        sub.unsubscribe();
        this.messages.splice(0, this.messages.length);
        this.messages.push(
            ...response.messages.map(msg => ({
                ...msg,
                timestamp: msg.timestamp ? new Date(msg.timestamp).toISOString() : new Date().toISOString(),  // Ensure it’s a valid timestamp
            }))
        );

        this.anchors = this.messages
            .slice(-40)
            .filter((x) => x.isAnchor)
            .map((x) => x.title);

        this.update_sessions();
        this._scrollToBottom();
    });
}

  $keydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      this.input += '\n';
      this.cdr.detectChanges();
    }
  }

  public async $submit(prompt?: string): Promise<void> {
    if (this.submitDisabled) return;
    if (this.uuid === undefined) return;

    this.loading = true;

    const content = prompt ?? this.input;

    if (content !== '/clear') this.messages.push({ isSelf: true, content });

    this.input = '';
    this._scrollToBottom();
    const analyzeCmd = '/get';
    const regenCmd = '/pull';

    if (content.toLowerCase() == '/help' || content.toLowerCase() == '/?') {
      this.messages.push({
        isSelf: false,
        content:
          '/help or /? to see this\n/clear to clear the screen\n/get {ticker} to get the analysis for a given ticker\n/pull to force system to reevaluate the analysis',
        lineBreak: true
      });
      this._scrollToBottom();
      this.loading = false;
    } else if (content === '/clear') {
      this.$clear();
    } else if (content.startsWith(analyzeCmd)) {
      this._ticker = content
        .substring(analyzeCmd.length + 1)
        .trim()
        .toUpperCase();
      this.loading = true;

      var sub2 = this._chat.getCachedAnalysis(this.teamName, this._identity.me.username, this.uuid, this._ticker).subscribe((response) => {
        this.loading = false;
        this.handleIncomingMessage(response);
        this.update_sessions();
        sub2.unsubscribe();
      });
    } else if (content.startsWith(regenCmd)) {
      if (content != regenCmd) {
      }
      const newTicker = content
        .substring(analyzeCmd.length + 1)
        .trim()
        .toUpperCase();
      if (newTicker) this._ticker = newTicker;

      if (!this._ticker) {
        console.error('Invalid ticker value:', this._ticker);
        this.loading = false;
        return;
      }

      this.loading = true;

      const modelNames = [this._ticker + ':top200_608'];
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
      const start = today - MS_PER_DAY * 180;
      const from = cutMillisFromTimestamp(new Date(start));
      const to = cutMillisFromTimestamp(new Date(today));
      this.anchors = [];
      this.messages.push({ isSelf: false, content: 'Fetching data ...' });

      var sub2 = this._chat
        .getModelData(this.teamName, this._identity.me.username, this.uuid, modelNames.join(','), '1day', from, to, 'mean', [this.llmName])
        .subscribe(() => {
          this.loading = false;
          this.update_sessions();
          sub2.unsubscribe();
        });
    } else {
      this._chat
        .chatAsync(this.teamName, this._identity.me.username, this.uuid, content, this._identity.me.selectedProjectName, this.model)
        .then((response) => {
          this.loading = false;
          this.messages.push({ isSelf: false, content: response.error ?? response.output, isError: !isNil(response.error) });
          this.update_sessions();
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);

          if (err instanceof HttpErrorResponse) {
            this.messages.push({ isSelf: false, content: 'Internal Server Error', isError: true });
          }
        })
        .finally(() => {
          this._scrollToBottom();
          this._focusPromptBox();
        });
    }
  }

  update_sessions() {
    this._chat.listAsync(this.teamName, this.username).then((sessions) => {
      this._rawSessions = sessions;
      this.sessions = this.organizeChatSessions();
      this.cdr.detectChanges();  // Ensure UI updates
    });
  }

  public $clear(): void {
    if (this.uuid !== undefined) return;

    this._chat
      .removeAsync(this.teamName, this._identity.me.username, this.uuid)
      .then((_response) => {
        this.loading = false;
        this.messages.splice(0, this.messages.length);
        this._widget.save(this.teamName);
        this.anchors = [];
      })
      .catch((err) => {
        this.loading = false;
        console.error(err);
      });
  }

  public $changeModel(model: string): void {
    this.modelChange.emit(model);
    this.model = model;
    this.modelName = this.availableModels.find((availableModel) => availableModel.model === model)?.name ?? 'Deepseek-R1-32B';
  }

  public $clickToggleHistory(): void {
    this.historyOpen = !this.historyOpen;
  }

  public $clickChat(session: ChatService.ChatSession): void {
    this.uuid = session.session.split(':').pop();
    this.getHistory();
  }

  public $clickNewChat(): void {
    this._chat.getNewUUIDAsync().then(({ uuid }) => {
      this.uuid = uuid;
      this.messages = [];
      this.anchors = [];
    });
  }

  public $clickSessionHistory($event: MouseEvent) {
    $event.stopPropagation();
  }

  public $clickRenameSession(session: ChatService.ChatSession): void {
    const uuid = session.session.split(':').pop();
    const title = prompt('New Session Name', session.title).trim();
    if (title === '' || title === null) return;

    this._chat.renameAsync(this.teamName, this.username, uuid, title).then((result) => {
      console.log(result);
      this._rawSessions = this._rawSessions.map((s) => {
        if (s.session === session.session) s.title = title;
        return s;
      });
      this.sessions = this.organizeChatSessions();
    });
  }

  public $clickDeleteSession(session: ChatService.ChatSession): void {
    const uuid = session.session.split(':').pop();

    this._chat.removeAsync(this.teamName, this.username, uuid).then(() => {
      this._rawSessions = this._rawSessions.filter((s) => s.session !== session.session);
      this.sessions = this.organizeChatSessions();

      if (this.uuid === uuid) {
        this.$clickNewChat();
      }
    });
  }

  private onPromptClick(topic) {
    this.chatScreen.scrollToAnchor(topic);
  }
}
