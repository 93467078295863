import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';
import { Meta, MetaParam } from 'src/app/models/meta';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() debounce: boolean = true;
  @Input() teamName: string = '';
  @Input() featureName: string = '';
  @Input() data: any = {};
  @Input() type: 'feature' | 'adapter' | 'component' | 'chart';
  @Input() meta: Meta = null;
  @Input() showDelete: boolean = true;
  @Input() disableName: boolean = false;
  @Input() showSave: boolean = false;
  @Input() readonly: boolean = false;
  @Input() flexible?: '';
  @Input() chunkDescription: boolean = false;
  @Input() saveOnChanges = false;
  @Output() onChanged = new EventEmitter<any>();
  @Output() onSaved = new EventEmitter<any>();
  @Output() onDeleted = new EventEmitter<any>();
  @Output() onLoaded = new EventEmitter<any>();

  public form: FormGroup;
  public isLoading = true;
  public get isDirty(): boolean {
    return this.form.dirty;
  }

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    (<any>window).dynamicForm = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadMeta();
  }

  async loadMeta() {
    if ((this.type === 'feature' || this.type === 'adapter') && this.meta === null)
      this.meta = await this.metaService.getAsync(this.type, this.teamName, this.featureName);

    this.form = this.toFormGroup(this.meta?.params ?? [], this.data);

    const debounceTimeDuration = this.debounce ? 2000 : 0;
    this.form.valueChanges.pipe(debounceTime(debounceTimeDuration)).subscribe((value: any) => {
      if (this.form.valid) {
        this.form.markAsPristine();
        if (!this.showSave)
          this.onSubmit();
      }
    });

    this.isLoading = false;
    this.onLoaded.emit(true);
  }

  toFormGroup = (params: MetaParam[], data: any) =>
    new FormGroup(
      Object.fromEntries(
        params.map(({ name, required }) => [
          name,
          new FormControl(
            {
              value: data[name] !== null ? data[name] : '',
              disabled: this.readonly
            },
            required ? Validators.required : void 0
          )
        ])
      )
    );

  getFormData() {
    Object.entries(this.form.getRawValue()).forEach(([key, val]) => {
      const param = this.meta.params.find((x) => x.name === key);
      if (!param) {
        this.data[key] = val;
      } else {
        switch (param.type) {
          case 'number':
            this.data[key] = +val || 0;
            break;
          case 'boolean':
            this.data[key] = val === null || (val as any)?.length === 0 ? false : val;
            break;
          default:
            this.data[key] = val;
            break;
        }
      }
    });

    return this.data;
  }

  onSubmit() {
    const formData = this.getFormData();
    this.onSaved.emit(formData);
  }

  onDelete() {
    const formData = this.getFormData();
    this.onDeleted.emit(formData);
  }

  onChange() {
    const formData = this.getFormData();
    this.onChanged.emit(formData);
  }
}
