import { Component, ComponentFactoryResolver, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounce } from 'src/app/directives/debounce.decorator';
import { DynamicComponentDirective } from 'src/app/directives/dynamic-component.directive';
import { ChatService } from 'src/app/services/chat.service';
import { BaseWidgetComponent } from '../strategy-grid/base-widget/base-widget.component';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-chat-screen',
  templateUrl: './chat-screen.component.html',
  styleUrls: ['./chat-screen.component.scss']
})
export class ChatScreenComponent implements OnInit {
  @ViewChild('content', { static: true }) private _content: ElementRef<HTMLDivElement>;
  @ViewChildren(DynamicComponentDirective) dynamicHosts!: QueryList<DynamicComponentDirective>;


  @Input() messages: ChatService.ChatMessage[] = [];
  @Input() loading = false;
  @Input() embed = false;

  constructor(
    private _snackBar: MatSnackBar,
    private _identity: IdentityService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}
  ngOnInit(): void {
    this.loadDynamicComponents();
  }

  rendered = [];
  loadDynamicComponents(): void {
    this.dynamicHosts.forEach((host, index) => {
      if(this.rendered.includes(index))
        return;
      const componentType = this.messages[index].componentType; // Adjust as necessary
      if (componentType) {
        const componentRef = host.viewContainerRef.createComponent<BaseWidgetComponent>(componentType);
        const instance = componentRef.instance;
        instance.teamName = this._identity.me.selectedTeamName;
        this.rendered.push(index);
      }
    });
  }

  @debounce(100)
  public scrollToTop(): void {
    this._content.nativeElement.scrollTop = 0;
  }

  @debounce(100)
  public scrollToBottom(): void {
    this._content.nativeElement.scrollTop = this._content.nativeElement.scrollHeight;
  }

  @debounce(100)
  public scrollToAnchor(anchorName: string): void {
    const anchor = this._content.nativeElement.querySelector(`a[name="${anchorName}"]`);
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  public copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this._snackBar.open('Copied to clipboard!', 'OK', { duration: 2000 });
    }).catch(err => {
      console.error('Failed to copy text:', err);
    });
  }

}
