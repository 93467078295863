import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Point } from 'src/app/models/point';
import { Workflow } from 'src/app/models/workflow';
import { MoveEvent } from 'src/app/models/svg-canvas-event-manager';
import { Node } from 'src/app/models/workflow-node';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowDrawerMode } from '../definition.component'; // Ensure MULTI_EDIT is added here if needed
import { MatMenuTrigger } from '@angular/material/menu';
import { NgxMatColorPickerInputEvent } from '@angular-material-components/color-picker';
import { Connection } from 'src/app/models/workflow-connection';
import { SVGCanvasComponent } from 'src/app/components/svg-canvas/svg-canvas.component';

export interface WorkflowDrawerEvent {
  mode?: WorkflowDrawerMode;
  component?: Node;
  id: string;
}

export type WorkflowCanvasGridBehaviour = 'hidden' | 'show' | 'snap';

@Component({
  selector: 'app-workflow-canvas',
  templateUrl: './workflow-canvas.component.html',
  styleUrls: ['./workflow-canvas.component.scss', './workflow-canvas.vars.scss']
})
export class WorkflowCanvasComponent implements OnInit, AfterViewInit {
  public workflow: Workflow = {
    id: '',
    name: '',
    description: '',
    assets: [],
    transfer: null,
    device: 'cpu',
    tags: [],
    nodes: [],
    connections: []
  };
  public nodeIDs: string[] = [];
  public connIDs: string[] = [];
  public grid: WorkflowCanvasGridBehaviour = 'snap';
  private _workflow$: Subscription;

  @Input() formDirty: boolean = false;
  @Input() isWidget: boolean = false;
  @Output() save = new EventEmitter<Workflow>();
  @Output() drawer = new EventEmitter<WorkflowDrawerEvent>();
  @Output() delete = new EventEmitter<string>();
  @Output() back = new EventEmitter<void>();

  @ViewChild('svgCanvas', { static: true }) svgCanvas: SVGCanvasComponent;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  public connectionInProgress?: { from: Node; to: Point } = undefined;
  public closestNode?: Node = undefined;
  public contextMenuPosition = { x: '0px', y: '0px' };

  public get view() {
    return this.svgCanvas?.view;
  }

  public get showGrid() {
    return this.grid !== 'hidden';
  }

  public get snapToGrid() {
    return this.grid === 'snap';
  }

  /* #region Lifecycle */
  constructor(private _workflowService: WorkflowService) {
    (<any>window).wfc = this;
  }

  public ngOnInit(): void {
    // Subscribe to workflow updates.
    this.$nextWorkflow(this._workflowService.workflow);
    this._workflow$ = this._workflowService.workflow$.subscribe((wf) => {
      this.$nextWorkflow(wf);
    });
  }

  public ngOnDestroy() {
    this._workflow$.unsubscribe();
  }

  ngAfterViewInit(): void {
    console.debug("🔧 Adjusting SVG Size & ViewBox");
    setTimeout(() => {
      const canvasElement = this.svgCanvas?.svgCanvas?.nativeElement;
      if (!canvasElement) {
        console.error("❌ Canvas element is missing!");
        return;
      }
      const svgElement = canvasElement.querySelector("svg");
      if (!svgElement) {
        console.error("❌ SVG element not found in canvas after delay!");
        return;
      }
      console.debug("✅ SVG Element Found! Applying Fixes...");
      svgElement.setAttribute("width", "100%");
      svgElement.setAttribute("height", "100%");
      svgElement.setAttribute("viewBox", "-50 -50 1350 950"); // Adjust if needed
      svgElement.style.overflow = "visible";
      // Log bounding box after applying changes
      setTimeout(() => {
        const bbox = svgElement.getBoundingClientRect();
        console.debug(`🧐 SVG Size After Fix: width=${bbox.width}, height=${bbox.height}, x=${bbox.x}, y=${bbox.y}`);
      }, 500);
    }, 100); // Small delay ensures DOM updates
  }
  /* #endregion */

  /* #region Events */

  /**
   * Called when the workflow is updated.
   * If multiple nodes are selected, we emit a MULTI_EDIT event;
   * if only a single node is selected, we emit EDITCOMPONENT.
   */
  public $nextWorkflow = (wf?: Workflow): void => {
    if (wf?.selectedNodes && wf.selectedNodes.length > 1) {
      // Multi-edit mode for multiple selections.
      this.drawer.emit({
        id: wf.id,
        mode: 'MULTI_EDIT'
      });
    } else if (wf?.selectedNode || (wf.selectedNodes && wf.selectedNodes.length === 1)) {
      const nodeId = wf.selectedNode || wf.selectedNodes[0];
      this.drawer.emit({
        id: wf.id,
        mode: 'EDITCOMPONENT',
        component: this._workflowService.getNodeByID(nodeId)
      });
    } else {
      this.drawer.emit({ id: wf.id });
    }
    this.workflow = wf;
    this.nodeIDs = wf?.nodes.map((n) => n.id) ?? [];
    this.connIDs = wf?.connections.map((c) => c.id) ?? [];
  };


  /**
   * New method to handle left-click (or tap) on a node.
   * If a modifier key is pressed, toggle the node in multi-selection;
   * otherwise, select only this node.
   */
  public $nodeClicked($event: MouseEvent, nodeID: string): void {
    if ($event.ctrlKey || $event.shiftKey) {
      // Multi-select: toggle node selection.
      if (this.workflow.selectedNodes && this.workflow.selectedNodes.includes(nodeID)) {
        this._workflowService.removeNodeFromSelection(nodeID);
      } else {
        const current = this.workflow.selectedNodes || [];
        this._workflowService.selectNodes([...current, nodeID]);
      }
    } else {
      // No modifier: select only this node.
      this._workflowService.selectNodes([nodeID]);
    }
    // Emit drawer event based on current selection.
    const currentSelection = this.workflow.selectedNodes || [];
    if (currentSelection.length > 1) {
      this.drawer.emit({ id: this.workflow.id, mode: 'MULTI_EDIT' });
    } else {
      const selectedNode = this._workflowService.getNodeByID(nodeID);
      this.drawer.emit({ id: this.workflow.id, mode: 'EDITCOMPONENT', component: selectedNode });
    }
  }

  /**
   * Updated context menu handler for a node.
   * Checks for modifier keys to support multi-selection.
   */
  public $contextmenuNode = ($ev: PointerEvent, nodeID: string) => {
    $ev.preventDefault();
    $ev.stopPropagation();
    const mouseEvent = $ev as MouseEvent;
    if (mouseEvent.ctrlKey || mouseEvent.shiftKey) {
      // Toggle node selection for multi-select.
      if (this.workflow.selectedNodes && this.workflow.selectedNodes.includes(nodeID)) {
        this._workflowService.removeNodeFromSelection(nodeID);
      } else {
        const current = this.workflow.selectedNodes || [];
        this._workflowService.selectNodes([...current, nodeID]);
      }
    } else {
      // No modifier: single selection.
      this._workflowService.selectNodes([nodeID]);
    }
    // Open the context menu with the clicked node.
    this.openContextMenu($ev, {
      type: 'node',
      item: this._workflowService.getNodeByID(nodeID)
    });
  };

  public $contextmenuBase = ($ev: PointerEvent) => {
    $ev.preventDefault();
    $ev.stopPropagation();
    this.openContextMenu($ev, {
      type: 'grid',
      item: this
    });
  };

  public $contextmenuConnection = ($ev: PointerEvent, connID: string) => {
    $ev.preventDefault();
    $ev.stopPropagation();
    this.openContextMenu($ev, {
      type: 'connection',
      item: this._workflowService.getConnectionByID(connID)
    });
  };

  public $tap = (): void => {
    // Tapping on the canvas background clears any node selection.
    this._workflowService.selectNodes([]);
    this.drawer.emit({ id: this.workflow.id });
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $move = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $moveEnd = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $pinch = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
  };

  public $clickAdd = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.drawer.emit({ id: this.workflow.id, mode: 'ADDCOMPONENT' });
  };

  public $clickSave = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.save.emit(this.workflow);
  };

  public $clickSettings = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.drawer.emit({ id: this.workflow.id, mode: 'SETTINGS' });
  };

  public $clickDelete = (): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    this.delete.emit(this.workflow.id);
  };

  public $clickBack = (): void => {
    this.back.emit();
  };

  public $dragConnection = ($ev: { node: Node; e: MoveEvent }): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    const mousePosition = this.coordsToSVG(
      $ev.e.detail.x - this.view.offsetLeft!,
      $ev.e.detail.y - this.view.offsetTop!
    );
    this.closestNode = this.getClosestInPortNode(mousePosition);
    this.connectionInProgress = {
      from: $ev.node,
      to: this.closestNode?.inPortPosition ?? mousePosition
    };
  };

  public $dragConnectionEnd = ($ev: { node: Node; e: MoveEvent }): void => {
    if (this.trigger.menuOpen) this.closeContextMenu();
    if (this.closestNode) {
      this._workflowService.createConnection($ev.node, this.closestNode);
    }
    Object.assign(this, {
      connectionInProgress: undefined,
      closestNode: undefined
    });
  };

  public $nodeColorChange = ($ev: NgxMatColorPickerInputEvent, node: Node): void => {
    node.color = $ev.value;
  };

  public $nodeColorPickerClose = (node: Node): void => {
    this._workflowService.updateNode(node.id, { color: node.color });
  };

  public $animateConnection = (connection: Connection): void => {
    this._workflowService.updateConnection(connection.id, {
      animated: !connection.animated
    });
  };
  /* #endregion */

  public getClosestInPortNode(loc: Point): Node | undefined {
    const { nodes } = this.workflow;
    const minDist: number = 60;
    let closestNode: Node | undefined;
    let closestDist: number;
    for (const node of nodes) {
      const distToMouse = node.inPortPosition.distanceTo(loc);
      if (distToMouse <= minDist) {
        if (!closestNode) {
          closestNode = node.clone();
          closestDist = distToMouse;
        } else {
          if (distToMouse < closestDist!) {
            closestNode = node.clone();
            closestDist = distToMouse;
          }
        }
      }
    }
    return closestNode;
  }

  public coordsToSVG = (x: number, y: number): Point =>
    new Point((x - this.view.x) / this.view.scale, (y - this.view.y) / this.view.scale);

  private _openContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    this.contextMenuPosition.x = `${$ev.pageX}px`;
    this.contextMenuPosition.y = `${$ev.pageY}px`;
    this.trigger.menuData = data;
    this.trigger.openMenu();
  }

  private _refreshContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    const sub = this.trigger.menuClosed.subscribe(() => {
      sub.unsubscribe();
      this._openContextMenu($ev, data);
    });
    this.trigger.closeMenu();
  }

  public openContextMenu($ev: PointerEvent, data: { type: string; item: unknown }): void {
    // If any node or connection is selected (and we’re not in multi-select), clear the selection.
    if (
      (this.workflow.selectedNode || this.workflow.selectedConnection) &&
      (!this.workflow.selectedNodes || this.workflow.selectedNodes.length < 2)
    ) {
      this._workflowService.selectNode();
      this._workflowService.selectConnection();
    }
    this.drawer.emit({ id: this.workflow.id });
    // Set up context menu
    if (this.trigger.menuOpen) this._refreshContextMenu($ev, data);
    else this._openContextMenu($ev, data);
  }

  public closeContextMenu(): void {
    this.trigger.closeMenu();
    this.contextMenuPosition = { x: '0px', y: '0px' };
    this.trigger.menuData = {};
  }
}
