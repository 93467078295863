import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Definition, ShallowDefinition } from '../models/definition';

@Injectable({
  providedIn: 'root'
})
export class DefinitionsService {
  constructor(private http: HttpClient) {
    window.$definitions = this;
  }

  public load = (teamName: string) => this.http.get<ShallowDefinition[]>(`${ConfigService.apiUrl}/data/definitions/${teamName}`);

  public loadAsync = async (teamName: string) => lastValueFrom(this.load(teamName));

  public get = (teamName: string, name: string) =>
    this.http.get<Definition>(`${ConfigService.apiUrl}/data/definition/${teamName}/${name}`);

  public getAsync = async (teamName: string, rdsName: string) => lastValueFrom(this.get(teamName, rdsName));

  public init = (teamName: string) => this.http.post(`${ConfigService.apiUrl}/data/definition/init/${teamName}`, {});

  public initAsync = async (teamName: string) => lastValueFrom(this.init(teamName));

  public duplicate = (teamName: string, name: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/definition/duplicate/${teamName}`, { name });

  public duplicateAsync = async (teamName: string, name: string) => lastValueFrom(this.duplicate(teamName, name));

  public update = (teamName: string, data: any, originalName: string | undefined = undefined) =>
    this.http.post(`${ConfigService.apiUrl}/data/definition/update/${teamName}`, { data, originalName });

  public updateAsync = async (teamName: string, data: any, originalName: string | undefined = undefined) =>
    lastValueFrom(this.update(teamName, data, originalName));

  public remove = (teamName: string, name: string) =>
    this.http.post(`${ConfigService.apiUrl}/data/definition/delete/${teamName}`, { name });

  public removeAsync = async (teamName: string, name: string) => lastValueFrom(this.remove(teamName, name));
}
