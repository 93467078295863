import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';
import { DatasetsService } from 'src/app/services/datasets.service';
import { RawComponent } from '../../data/raw/raw.component';
import { RawService } from 'src/app/services/raw.service';

@Component({
  selector: 'app-raw-data-widget',
  templateUrl: './raw-data-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './raw-data-widget.component.scss']
})
export class RawDataWidgetComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild(RawComponent) rawComponent!: RawComponent;

  public path: string;
  public fileType: string;
  public title: string = null;
  public datasets: string[] = [];
  public allDatasets: string[] = [];
  public filteredDatasets: string[] = [];
  public selectedDataset: string = null;
  public filterText = null;

  public currentPage = 1;
  public pageSize = 10;
  public hasPrevPage = false;
  public hasNextPage = false;
  public right = 80;
  public left = 20;
  public isLoading: boolean = true;

  constructor(
    private _widgetService: WidgetService,
    private _datasetsService: DatasetsService,
    private _rawService: RawService
  ) {
    super();
    (<any>window).rawWidget = this;
  }

  ngOnInit(): void {
    const sub = this._datasetsService.load(this.teamName).subscribe((results) => {
      sub.unsubscribe();
      this.isLoading = false;
      if (!results) return;

      this.datasets = results;
      this.datasets = this.datasets.sort((a, b) => a.localeCompare(b));

      this.datasets.unshift(this._rawService.allFilesText);

      this.allDatasets = this.datasets;
      this.filteredDatasets = this.allDatasets;
      this.paginateDatasets(1);
      this.onSelect(this._rawService.allFilesText)
    });

    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      setTimeout(() => {
        try {
          (<any>window).raw.optimizePageSize();
        } catch {}
      }, 100);
    });
  }

  onViewData(input: any) {
    this.path = input.path;
    this.fileType = input.fileType;
    this.title = input.path.split('/').pop();
    this.widgetView = 'DETAIL';
    this.right = 100;
    this.left = 0;
  }

  onSelect(item: string) {
    this.selectedDataset = item === this.selectedDataset ? null : item;
    this.rawComponent?.selectDataset(this.selectedDataset);
  }


  close() {
    if (this.widgetView === 'DETAIL') {
      this.path = null;
      this.fileType = null;
      this.title = null;
      this.widgetView = 'LIST';
      this.right = 80;
      this.left = 20;

    } else {
      super.close();
    }
  }

  paginateDatasets(page: number) {
    // const start = (page - 1) * this.pageSize;
    // const end = page * this.pageSize;

    // this.hasNextPage = false;
    // if (end < this.filteredDatasets.length) this.hasNextPage = true;

    // this.hasPrevPage = false;
    // if (start > 0) this.hasPrevPage = true;

    // this.datasets = this.filteredDatasets.slice(start, end);
  }

  next() {
    if (!this.hasNextPage) return;
    this.currentPage++;
    this.paginateDatasets(this.currentPage);
  }

  prev() {
    if (!this.hasPrevPage) return;
    this.currentPage--;
    this.paginateDatasets(this.currentPage);
  }

  async addDataset() {
    const name = prompt("Enter name for new dataset");
    if (!name || name.trim().length === 0) return;

    const exists = this.allDatasets.find(x => x.toLowerCase() ===  name.toLowerCase());
    if (exists) {
      alert("A dataset with that name already exists.");
      return;
    }

    await this._datasetsService.add(this.teamName, name);
    this.allDatasets.push(name);
    this.filteredDatasets = this.allDatasets;
    this.filteredDatasets.sort((a, b) => a.localeCompare(b));

    setTimeout(() => {
      this.applyFilter(name);
      this.onSelect(name);
    }, 0);

  }

  async onDeleteDataset($event, item) {
    if (!confirm(`Are you sure want to delete dataset ${item}?`)) {
      return;
    }

    $event.stopPropagation();

    await this._datasetsService.remove(this.teamName, item);
    this.allDatasets = this.allDatasets.filter(x => x !== item);
    this.onSelect(null);
    this.applyFilter(null);
  }

  applyFilter(filterText: string) {

    this.filterText = filterText;
    if (!filterText || filterText.length === 0) {
      this.filteredDatasets = this.allDatasets;
    } else {
      const filtered = this.allDatasets.filter(x => x.toLowerCase().includes(filterText.toLowerCase()));
      this.filteredDatasets = filtered;
    }

    // this.currentPage = 1;
    // this.paginateDatasets(this.currentPage);
  }
}
