import { Component, ViewChild, OnInit, Input, ChangeDetectorRef, EventEmitter, Output, OnDestroy, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PredictEngineService, Worker } from 'src/app/services/predict-engine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConfigService } from 'src/app/services/config.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SocketService } from 'src/app/services/socket.service';
import { simplifyStatus } from '../../service-status-icon/service-status-icon.component';

export type SystemServiceState =
  | 'plugged'
  | 'mounted'
  | 'running'
  | 'waiting'
  | 'abandoned'
  | 'exited'
  | 'dead'
  | 'active'
  | 'listening'
  | 'updating';
export const SERVICE_STATES = [
  'plugged',
  'mounted',
  'running',
  'waiting',
  'abandoned',
  'exited',
  'dead',
  'active',
  'listening',
  'updating'
] as const;

@Component({
  selector: 'app-deploys',
  templateUrl: './deploys.component.html',
  styleUrls: ['./deploys.component.scss']
})
export class DeploysComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  selectedRow: any;

  // @Input() displayedColumns: string[] = [];
  @Input() isWidget: boolean = false;
  @Input() teamName: string;
  @Input() parentId: string;

  @Output() serviceClick = new EventEmitter<{ parentId: string; serviceName: string }>();

  private _subs = new SubscriptionContainer();

  public pageSize: number = 10;
  public dataSource = new MatTableDataSource<Worker>([]);
  public selection = new SelectionModel<any>(true, []);
  public loading = true;
  public displayedColumns = ['select', 'name', 'status', 'started', 'models', 'action'];
  public serviceCounts = { active: 0, warn: 0, error: 0, unknown: 0, all: 0, known: 0 };
  public get allSelectedCanBeStarted() {
    return this.selection.selected.reduce((allCanBeStarted, deploy) => allCanBeStarted && deploy.status !== 'running', true);
  }

  public get allSelectedCanBeStopped() {
    return this.selection.selected.reduce((allCanBeStarted, deploy) => allCanBeStarted && deploy.status === 'running', true);
  }

  public all: boolean = false;

  constructor(
    private _host: ElementRef,
    private activatedRoute: ActivatedRoute,
    private predictEngineService: PredictEngineService,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private _socketService: SocketService
  ) {
    (<any>window).deploys = this;
  }

  ngOnInit(): void {
    this.dataSource.data = [];
    if (this.isWidget) this.subscribeToSocketEvents();
  }

  ngAfterViewInit(): void {
    if (this.isWidget) {
      this.init();
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.init();
      });
    }
  }

  ngOnDestroy(): void {
    this._socketService.leaveRoom('predict');
    this._socketService.leaveRoom('deploys');
    this._subs.dispose();
  }

  subscribeToSocketEvents(): void {
    this._socketService.joinRoom('deploys');
    this._subs.add = this.predictEngineService.serviceClick$.subscribe((response) => {
      if (response.parentId !== this.parentId) return;
      this.serviceClick.emit(response);
    });
    this._subs.add = this._socketService.subscribeToRoomEvents('deploys', (packet: any) => {
      if ((packet.origin ?? '') === 'deploys.update') this.init();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  init() {
    this.loading = true;
    const sub = this.predictEngineService.load(this.teamName).subscribe((response) => {
      sub.unsubscribe();

      const deploys = ConfigService.features.chart ? response : response.filter((x) => x.name.toLowerCase() !== 'lit-udf');
      this.serviceCounts = { active: 0, warn: 0, error: 0, unknown: 0, all: 0, known: 0 };
      deploys.forEach(this.processStats.bind(this));
      this.dataSource.data = deploys;
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.optimizePageSize();
      }, 0);

      this.loading = false;
    });
  }

  onResize() {
    this.optimizePageSize();
  }

  optimizePageSize() {
    if (this.isWidget && this._host.nativeElement.parentElement) {
      const innerHeight = this._host.nativeElement.parentElement.clientHeight;
      const cardsHeight = 150 + 16;
      const searchHeight = 64 + 31;
      const headSize = 38;
      const rowSize = 57;
      const footSize = 58;
      this.pageSize = Math.floor((innerHeight - cardsHeight - searchHeight - headSize - footSize) / rowSize);
    } else {
      var x = window.innerHeight;
      var blocks = Math.round(0.00493601 * x - 2);
      this.pageSize = blocks * 5;
    }
    if (this.paginator) this.paginator._changePageSize(this.pageSize);
  }

  selectAll() {
    this.dataSource.filteredData.forEach((deploy) => this.selection.select(deploy));
  }

  $clickStartSelected() {
    this.updateSelected();
    this.selection.selected.forEach((x) => (x.status = 'updating'));
    Promise.all(this.selection.selected.map(this.onStart.bind(this))).then((values) => {
      console.log('started selected', { values });
    });
  }

  $clickRestartSelected() {
    this.updateSelected();
    Promise.all(this.selection.selected.map(this.onRestart.bind(this))).then((values) => {
      console.log('started selected', { values });
    });
  }

  $clickStopSelected() {
    this.updateSelected();
    Promise.all(this.selection.selected.map(this.onStop.bind(this))).then((values) => {
      console.log('started selected', { values });
    });
  }

  updateSelected() {
    for (let selected of this.selection.selected) {
      selected.status = 'updating';
      this.updateDeploy(selected);
    }
  }

  async onStart(item: Worker) {
    const sub = this.predictEngineService.start(this.teamName, item.name, item.static).subscribe((response) => {
      sub.unsubscribe();
      if (response) this.updateDeploy(response);
    });
  }

  async onStop(item: Worker) {
    const sub = this.predictEngineService.stop(this.teamName, item.name, item.static).subscribe((response) => {
      sub.unsubscribe();
      if (response) this.updateDeploy(response);
    });
  }

  async onRestart(item: Worker) {
    const sub = this.predictEngineService.restart(this.teamName, item.name, item.static).subscribe((response) => {
      sub.unsubscribe();
      if (response) this.updateDeploy(response);
    });
  }

  updateDeploy(updatedDeploy: Worker) {
    if (updatedDeploy.name.includes(':')) {
      const lastIndex = updatedDeploy.name.lastIndexOf(':');
      updatedDeploy.name = updatedDeploy.name.substring(lastIndex + 1);
    }

    const deploy: any = this.dataSource.filteredData.find((x) => x.name === updatedDeploy.name);
    this.serviceCounts[deploy.rendering.statusClass]--;
    Object.assign(deploy, updatedDeploy);
    const statusClass = this.updateStatus(deploy);
    this.serviceCounts[statusClass]++;
    this.changeDetectorRefs.detectChanges();
  }

  add() {
    if (this.isWidget) {
      this.serviceClick.emit({ parentId: this.parentId, serviceName: 'add' });
    } else {
      this.router.navigate([this.teamName, 'deploy', 'add']);
    }
  }

  updateStatus(row) {
    const statusClass = simplifyStatus({ active: row.active, status: row.status });
    row.rendering = { statusClass };
    return statusClass;
  }

  processStats(row) {
    const statusClass = this.updateStatus(row);
    this.serviceCounts[statusClass] += 1;
    this.serviceCounts.all += 1;
    if (statusClass !== 'unknown') this.serviceCounts.known += 1;
  }

  rowClick(row) {
    if (this.isWidget) {
      this.predictEngineService.serviceClick$.next({ parentId: this.parentId, serviceName: row.name });
    } else {
      this.router.navigate([this.teamName, 'deploy', row.name]);
    }
  }

  $updateStatus($event: Event, row, action: 'start' | 'stop' | 'restart') {
    $event.stopPropagation();
    row.status = 'updating';
    if (action === 'start') this.onStart(row);
    else if (action === 'stop') this.onStop(row);
    else if (action === 'restart') this.onRestart(row);
  }

  async toggleSelection(row) {
    this.all = false;
    this.selection.toggle(row);
  }

  toggleAll() {
    if (this.all) this.dataSource.filteredData.forEach((deploy) => this.selection.select(deploy));
    else this.selection.clear();
  }
}
