import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseWidgetComponent } from '../components/strategy-grid/base-widget/base-widget.component';
import { GridsterItem, GridsterItemComponent } from 'angular-gridster2';
import { NumberRange } from 'scichart';

import { AssetsWidgetComponent } from '../components/strategy-grid/assets-widget/assets-widget.component';
import { BreakoutWidgetComponent } from '../components/strategy-grid/breakout-widget/breakout-widget.component';
import { BuildsWidgetComponent } from '../components/strategy-grid/builds-widget/builds-widget.component';
import { DefinitionsWidgetComponent } from '../components/strategy-grid/definitions-widget/definitions-widget.component';
import { DeploysWidgetComponent } from '../components/strategy-grid/deploys-widget/deploys-widget.component';
import { ExperimentsWidgetComponent } from '../components/strategy-grid/experiments-widget/experiments-widget.component';
import { FeatureSelectorWidgetComponent } from '../components/strategy-grid/feature-selector-widget/feature-selector-widget.component';
import { GpusWidgetComponent } from '../components/strategy-grid/gpus-widget/gpus-widget.component';
import { HostsWidgetComponent } from '../components/strategy-grid/hosts-widget/hosts-widget.component';
import { InspectWidgetComponent } from '../components/strategy-grid/inspect-widget/inspect-widget.component';
import { LabWidgetComponent } from '../components/strategy-grid/lab-widget/lab-widget.component';
import { ModelChartWidgetComponent } from '../components/strategy-grid/model-chart-widget/model-chart-widget.component';
import { ModelInputsWidgetComponent } from '../components/strategy-grid/model-inputs-widget/model-inputs-widget.component';
import { PacketWidgetComponent } from '../components/strategy-grid/packet-widget/packet-widget.component';
import { ChatWidgetComponent } from '../components/strategy-grid/chat-widget/chat-widget.component';
import { SparklinesWidgetComponent } from '../components/strategy-grid/sparklines-widget/sparklines-widget.component';
import { RawDataWidgetComponent } from '../components/strategy-grid/raw-data-widget/raw-data-widget.component';
import { SchemasWidgetComponent } from '../components/strategy-grid/schemas-widget/schemas-widget.component';
import { StrategyWidgetComponent } from '../components/strategy-grid/strategy-widget/strategy-widget.component';
import { StreamWidgetComponent } from '../components/strategy-grid/stream-widget/stream-widget.component';
import { TerminalWidgetComponent } from '../components/strategy-grid/terminal-widget/terminal-widget.component';
import { TickWidgetComponent } from '../components/strategy-grid/tick-widget/tick-widget.component';
import { VaultWidgetComponent } from '../components/strategy-grid/vault-widget/vault-widget.component';
import { IndicatorWidgetComponent } from '../components/strategy-grid/indicator-widget/indicator-widget.component';
import { SettingsWidgetComponent } from '../components/strategy-grid/settings-widget/settings-widget.component';
import { MonacoWidgetComponent } from '../components/strategy-grid/monaco-widget/monaco-widget.component';
import { StrategyEditorWidgetComponent } from '../components/strategy-grid/strategy-editor-widget/strategy-editor-widget.component';
import { StreamsWidgetComponent } from '../components/strategy-grid/streams-widget/streams-widget.component';
import { CompilerWidgetComponent } from '../components/strategy-grid/compiler-widget/compiler-widget.component';
import { StreamRestWidgetComponent } from '../components/strategy-grid/stream-rest-widget/stream-rest-widget.component';
import { GridComponent } from '../components/strategy-grid/grid/grid.component';
import { PerfTableWidgetComponent } from '../components/strategy-grid/perf-table-widget/perf-table-widget.component';

export namespace WidgetService {
  // Since certain Widgets implement a different constructor, we'll ignore that
  export type WidgetComponent = Omit<typeof BaseWidgetComponent, 'constructor'>;
  export type WidgetName = keyof typeof WidgetService.REGISTRY;

  export type Widget = GridsterItem & {
    component: WidgetComponent;
    name: WidgetName;
    id: string;
    inputs: Record<string, any>;
    outputs: Record<string, any>;
    birthday: Date;
    defaultSpace: Pick<Required<GridSpace>, 'rows' | 'cols'>;
    savedCoords: number[];
    isMaximized: boolean;
  };

  export type AxisUpdateEvent = {
    id: string;
    visibleRange: NumberRange;
  };

  export type ChildEvent = { parentId: string };
  export type AddChildEvent = ChildEvent & { id: string };
  export type RemoveChildEvent = AddChildEvent;
  export type LoadModelEvent = ChildEvent;

  export type ParentChartUpdatedEvent = ChildEvent & { chartData: any[] };
  export type TickerUpdateEvent = ChildEvent & { ticker: string };
  export type IntervalUpdateEvent = ChildEvent & { interval: string };
  export type StreamUpdateEvent = ChildEvent & { streamName: string };
  export type StratsUpdateEvent = ChildEvent & { strats: string[] };
  export type StratEditEvent = ChildEvent;
  export type ModelsAdded = { models: string[] };
  export type AnnotationsUpdateEvent = ChildEvent & { myId: string; annotationMap: any[] };

  export type ResizeEvent = {
    item: Widget;
    itemComponent: GridsterItemComponent;
  };

  export type GridSpace = Partial<{
    x: number;
    y: number;
    rows: number;
    cols: number;
    maxItemRows: number;
    minItemRows: number;
    maxItemCols: number;
    minItemCols: number;
    minItemArea: number;
    maxItemArea: number;
  }>;
}

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  static readonly REGISTRY = {
    RestStreamWidget: StreamRestWidgetComponent,
    StreamWidget: StreamWidgetComponent,
    StreamsWidget: StreamsWidgetComponent,
    ModelChartWidget: ModelChartWidgetComponent,
    TickWidget: TickWidgetComponent,
    PacketWidget: PacketWidgetComponent,
    MonacoWidget: MonacoWidgetComponent,
    ModelInputsWidget: ModelInputsWidgetComponent,
    StrategyWidget: StrategyWidgetComponent,
    StrategyEditorWidget: StrategyEditorWidgetComponent,
    BreakoutWidget: BreakoutWidgetComponent,
    ExperimentsWidget: ExperimentsWidgetComponent,
    RawDataWidget: RawDataWidgetComponent,
    FeatureSelectorWidget: FeatureSelectorWidgetComponent,
    SchemasWidget: SchemasWidgetComponent,
    BuildsWidget: BuildsWidgetComponent,
    LabWidget: LabWidgetComponent,
    AssetsWidget: AssetsWidgetComponent,
    InspectWidget: InspectWidgetComponent,
    DefinitionsWidget: DefinitionsWidgetComponent,
    VaultWidget: VaultWidgetComponent,
    DeploysWidget: DeploysWidgetComponent,
    HostsWidget: HostsWidgetComponent,
    TerminalWidget: TerminalWidgetComponent,
    GPUsWidget: GpusWidgetComponent,
    ChatWidget: ChatWidgetComponent,
    SparklinesWidget: SparklinesWidgetComponent,
    IndicatorWidget: IndicatorWidgetComponent,
    SettingsWidget: SettingsWidgetComponent,
    CompilerWidget: CompilerWidgetComponent,
    PerfTableWidget: PerfTableWidgetComponent
  } as const;

  public parentXAxisUpdate$: Subject<WidgetService.AxisUpdateEvent> = new Subject();
  public childXAxisUpdate$: Subject<WidgetService.AxisUpdateEvent> = new Subject();
  public childAdd$: Subject<WidgetService.AddChildEvent> = new Subject();
  public childDelete$: Subject<WidgetService.RemoveChildEvent> = new Subject();
  public loadModelData$: Subject<WidgetService.LoadModelEvent> = new Subject();
  public parentChartDataUpdate$: Subject<WidgetService.ParentChartUpdatedEvent> = new Subject();
  public tickerUpdate$: Subject<WidgetService.TickerUpdateEvent> = new Subject();
  public intervalUpdate$: Subject<WidgetService.IntervalUpdateEvent> = new Subject();
  public streamUpdate$: Subject<WidgetService.StreamUpdateEvent> = new Subject();
  public stratsUpdate$: Subject<WidgetService.StratsUpdateEvent> = new Subject();
  public stratEdit$: Subject<WidgetService.StratEditEvent> = new Subject();
  public modelsAdded$: Subject<WidgetService.ModelsAdded> = new Subject();
  public annotationsUpdate$: Subject<WidgetService.AnnotationsUpdateEvent> = new Subject();
  public resize$: Subject<WidgetService.ResizeEvent> = new Subject();
  public restore$: Subject<void> = new Subject();
  public maximize$: Subject<void> = new Subject();

  public widgets: WidgetService.Widget[] = [];

  public grid: GridComponent = null;

  registerGrid(grid: GridComponent) {
    this.grid = grid;
  }

  createWidget(
    name: WidgetService.WidgetName,
    teamName: string,
    values?: Partial<WidgetService.Widget>,
    config?: Partial<{
      defaultSpace: WidgetService.GridSpace;
      baseInputs: Record<string, any>;
      outputs: Record<string, any>;
    }>
  ): WidgetService.Widget {
    const id = values?.id ?? `${name}${this.widgets.filter(({ id }) => id.startsWith(name)).length + 1}`;

    let nextLayerIndex = 0;
    if (values && values.layerIndex) {
      nextLayerIndex = values.layerIndex;
    } else if (this.widgets.length > 0) {
      nextLayerIndex = Math.max(...this.widgets.map((widget) => (widget.layerIndex ?? 0) + 1));
    }

    const savedCoords = values?.savedCoords ?? [0, 0, 0, 0];
    const isMaximized = savedCoords.reduce((partialSum, a) => partialSum + a, 0) != 0;

    let retval = {
      x: values?.x ?? config?.defaultSpace?.x ?? 1,
      y: values?.y ?? config?.defaultSpace?.y ?? 1,
      rows: values?.rows ?? config?.defaultSpace?.rows ?? 35,
      cols: values?.cols ?? config?.defaultSpace?.cols ?? 55,
      defaultSpace: {
        rows: config?.defaultSpace?.rows ?? 35,
        cols: config?.defaultSpace?.cols ?? 55
      },
      maxItemRows: values?.maxItemRows ?? config?.defaultSpace?.maxItemRows,
      minItemRows: values?.minItemRows ?? config?.defaultSpace?.minItemRows,
      maxItemCols: values?.maxItemCols ?? config?.defaultSpace?.maxItemCols,
      minItemCols: values?.minItemCols ?? config?.defaultSpace?.minItemCols,
      minItemArea: values?.minItemArea ?? config?.defaultSpace?.minItemArea,
      maxItemArea: values?.maxItemArea ?? config?.defaultSpace?.maxItemArea,
      layerIndex: nextLayerIndex,
      component: this.nameToComponent(name),
      name,
      id,
      inputs: { teamName, id, isMaximized, ...(config?.baseInputs ?? {}), ...(values?.inputs ?? {}) },
      outputs: config?.outputs ?? {},
      birthday: new Date(),
      savedCoords,
      isMaximized: false
    };

    retval.isMaximized = isMaximized;
    return retval;
  }

  nameToComponent(name: WidgetService.WidgetName): WidgetService.WidgetComponent {
    if (name in WidgetService.REGISTRY) return WidgetService.REGISTRY[name];

    console.warn(`unknown widget: ${name}`);
    return BaseWidgetComponent;
  }

  addWidget(teamName: string, widget: WidgetService.Widget) {
    this.widgets.push(widget);
    this.save(teamName);
  }

  removeWidget(teamName: string, ...ids: string[]) {
    for (const id of ids) {
      const found = this.widgets.findIndex((widget) => widget.id === id);
      if (found === -1) continue;
      this.widgets.splice(found, 1);
    }
    this.save(teamName);
  }

  updateWidget(teamName: string, id: string, widget: WidgetService.Widget) {
    const found = this.widgets.findIndex((widget) => widget.id === id);
    if (found === -1) return;

    this.widgets[found] = widget;
    this.save(teamName);
  }

  maximizeWidget(teamName: string, id: string) {
    const found = this.widgets.find((widget) => widget.id === id);
    if (!found) return;

    if (!found.isMaximized) {
      // I'll maximize twice if you ask, but I'll keep the first set of coordinates
      found.savedCoords = [found.x, found.y, found.cols, found.rows];
    }

    found.x = 0;
    found.y = 0;
    found.cols = Math.floor(window.innerWidth / this.grid.options.fixedColWidth) - 5;
    found.rows = Math.floor((window.innerHeight - 24) / this.grid.options.fixedRowHeight) - 3;
    found.isMaximized = true;
    found.inputs.isMaximized = true;

    this.maximize$.next();
    this.save(teamName);
  }

  toggleWidget(teamName: string, id: string) {
    const found = this.widgets.find((widget) => widget.id === id);
    if (!found) return;

    if (found.isMaximized) {
      this.restoreWidget(teamName, id);
    } else {
      this.maximizeWidget(teamName, id);
    }
  }

  restoreWidget(teamName: string, id: string) {
    const found = this.widgets.find((widget) => widget.id === id);
    if (!found) return;

    if (found.savedCoords.reduce((partialSum, a) => partialSum + a, 0) == 0) {
      found.cols = found.defaultSpace.cols;
      found.rows = found.defaultSpace.rows;
    } else {
      found.x = found.savedCoords[0];
      found.y = found.savedCoords[1];
      found.cols = found.savedCoords[2];
      found.rows = found.savedCoords[3];
    }
    found.savedCoords = [0, 0, 0, 0];
    found.isMaximized = false;
    found.inputs.isMaximized = false;

    this.restore$.next();
    this.save(teamName);
  }

  save(teamName: string) {
    localStorage.setItem(`${teamName}-widgets`, JSON.stringify(this.widgets));
  }
}
