import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SparklinesService } from 'src/app/services/sparklines-service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-sparklines-widget',
  templateUrl: './sparklines-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './sparklines-widget.component.scss']
})
export class SparklinesWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  availableDates: Date[] = [];
  selectedDate: Date | null = null;
  dataFrame = new MatTableDataSource<any>();
  displayedColumns: string[] = ['symbol', 'recommendation', 'value', 'recent'];
  filter: string = null;

  isLoading: boolean = false;

  constructor(
    private _sparklinesService: SparklinesService,
    private _widgetService: WidgetService
  ) {
    super();
  }

  public ngOnInit() {
    if ((<any>window).sparklines) (<any>window).sparklines.push(this);
    else (<any>window).sparklines = [this];

function parseDate(dateString) {
  const match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})/);
  if (match) {
    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const day = parseInt(match[3], 10);
    return new Date(year, month-1, day);
  } else {
    throw new Error("Invalid date format");
  }
}

    this.isLoading = true;
    const sub = this._sparklinesService.get_dates(this.teamName).subscribe({
      next: (response: string[]) => {
        this.availableDates = response.map(date => parseDate(date));
        if (this.availableDates.length > 0) {
          this.selectedDate = this.availableDates[0];
          this.fetchDataFrame(this.selectedDate);
        }
      },
      error: (err) => {
        console.error('Failed to fetch available dates:', err);
        this.isLoading = false;
      },
      complete: () => sub.unsubscribe()
    });
  }

  public ngAfterViewInit(): void {
    if (this.sort) {
      this.dataFrame.sort = this.sort;
    } else {
      console.warn('MatSort is not initialized.');
    }
  }

  applyFilter(filterValue: string) {
    this.dataFrame.filter = filterValue.trim().toLowerCase();
    this.filter = filterValue;
  }

  public fetchDataFrame(date: Date): void {
    this.isLoading = true;
    const formattedDate = date.toISOString().split('T')[0];
    const sub = this._sparklinesService.get_dataframe(this.teamName, formattedDate).subscribe({
      next: (response) => {
        const rows = Array.isArray(response) ? response : Object.values(response);
        this.dataFrame.data = rows;
      },
      error: (err) => {
        console.error('Failed to fetch data frame:', err);
      },
      complete: () => {
        this.isLoading = false;
        sub.unsubscribe();
      }
    });
  }

  public onDateChange(event: any): void {
    const selectedDate = event.value;
    if (selectedDate) {
      this.selectedDate = selectedDate;
      this.fetchDataFrame(selectedDate);
    }
  }

  public onRowClick(row: any): void {
    const symbol = row.symbol;
    const model = row.model;

    if (symbol && model && this._widgetService.grid) {

      this._widgetService.grid.addAgentWidget(null, symbol, "1day", null);

      // this._widgetService.grid.addStreamWidget(symbol, [], null, `${symbol}:${model}`, symbol);
    } else {
      console.warn('Unable to open stream widget: symbol or model is missing.');
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
