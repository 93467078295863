import { Component, ElementRef, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-template-widget',
  templateUrl: './template-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './template-widget.component.scss']
})
export class TemplateWidgetComponent extends BaseWidgetComponent implements OnInit {

  constructor(
    private _widgetService: WidgetService,
    private _elRef: ElementRef
  ) {
    super();
    (<any>window).template = this;
  }

  ngOnInit(): void { }
}
