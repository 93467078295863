// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monaco-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
}

#query-builder-container {
  padding: 10px;
}

.smol {
  height: 24px;
  line-height: 22px;
  font-size: 12px;
}

.monaco-container {
  position: relative; /* Changed from fixed */
  height: 100%;
  width: 100%;
  display: flex; /* Use flex for better responsiveness */
  flex-direction: column;
}

ngx-monaco-editor {
  display: block; /* Ensure block display for proper flex behavior */
  height: 100%;
  width: 100%; /* Allow it to take up full width */
  resize: both; /* Optional: Allow manual resize by dragging */
  overflow: auto; /* Handle any overflow */
}

#strategy-toolbar {
  padding: 5px;
}

#strat-button {
  float: right;
  margin-left: 7px;
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/strategy-editor-widget/strategy-editor-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA,EAAA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA,EAAA,uCAAA;EACA,sBAAA;AACF;;AAEA;EACE,cAAA,EAAA,kDAAA;EACA,YAAA;EACA,WAAA,EAAA,mCAAA;EACA,YAAA,EAAA,8CAAA;EACA,cAAA,EAAA,wBAAA;AACF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AAAF","sourcesContent":[".monaco-container {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  display: block;\n}\n\n#query-builder-container {\n  padding: 10px;\n}\n\n.smol {\n  height: 24px;\n  line-height: 22px;\n  font-size: 12px;\n}\n\n.monaco-container {\n  position: relative; /* Changed from fixed */\n  height: 100%;\n  width: 100%;\n  display: flex; /* Use flex for better responsiveness */\n  flex-direction: column;\n}\n\nngx-monaco-editor {\n  display: block; /* Ensure block display for proper flex behavior */\n  height: 100%;\n  width: 100%; /* Allow it to take up full width */\n  resize: both; /* Optional: Allow manual resize by dragging */\n  overflow: auto; /* Handle any overflow */\n}\n\n\n#strategy-toolbar {\n  padding: 5px;\n}\n\n#strat-button {\n  float: right;\n  margin-left: 7px;\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
