import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SparklinesService {
  constructor(private http: HttpClient) {}

  public get_dates(teamName: string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/sparklines/${teamName}/available_dates`);
  }

  public get_dataframe(teamName: string, date:string): Observable<any> {
    return this.http.get(`${ConfigService.apiUrl}/sparklines/${teamName}/dataframe/${date}`);
  }
}
