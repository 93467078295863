// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex; /* Ensures it stretches inside flexible layouts */
  flex: 1 1 auto; /* Allows it to fill available space */
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg {
  width: 100% !important;
  height: 100% !important;
  touch-action: manipulation;
  box-sizing: border-box;
  background: #e7ebee;
  pointer-events: painted;
}

.button-stack {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  bottom: 0.5rem;
  left: calc(3px + 0.5rem);
}

.mat-drawer-container {
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/models/definition/workflow-canvas/workflow-canvas.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA,EAAA,iDAAA;EACA,cAAA,EAAA,sCAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;EACA,0BAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAGA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;EACA,wBAAA;AAAF;;AAGA;EACE,YAAA;EACA,yBAAA;UAAA,iBAAA;AAAF","sourcesContent":[":host {\n  display: flex; /* Ensures it stretches inside flexible layouts */\n  flex: 1 1 auto; /* Allows it to fill available space */\n  flex-grow: 1;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\nsvg {\n  width: 100% !important;\n  height: 100% !important;\n  touch-action: manipulation;\n  box-sizing: border-box;\n  background: #e7ebee;\n  pointer-events: painted;\n\n}\n\n.button-stack {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  bottom: 0.5rem;\n  left: calc(3px + 0.5rem);\n}\n\n.mat-drawer-container {\n  height: 100%;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
