import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RawService, RelocateRawParameters } from 'src/app/services/raw.service';
import { UploadService } from 'src/app/services/upload.service';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { MatDialog } from '@angular/material/dialog';
import { MoveRawDialogComponent, MoveRawDialogData } from '../../move-raw-dialog/move-raw-dialog.component';
import { CacheFileService } from 'src/app/services/cache-file.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Project } from 'src/app/models/project';
import { MatSelect } from '@angular/material/select';
import { TerminalComponent } from '../../terminal/terminal.component';
import { SubscriptionContainer } from 'src/app/models/subscription-container';
import { SettingsService } from 'src/app/services/settings.service';
import { DatasetsService } from 'src/app/services/datasets.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.scss']
})
export class RawComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SplitComponent) split: SplitComponent;
  @ViewChild('datasetSearchInput') datasetSearchInput!: ElementRef;

  @ViewChild('rawfileTable') rawfileTable!: ElementRef;

  @ViewChild(MatSelect) projectSelect: MatSelect;
  @ViewChild(TerminalComponent) terminal: TerminalComponent;
  @ViewChild('dsSelectionList') dsSelectionList!: MatSelectionList;

  @Input() isWidget: boolean = false;
  @Input() allDatasets: string [] = [];
  @Output() onViewData = new EventEmitter<{ path: string; fileType: string }>();

  public isLoading = true;
  public pageSize = 10;
  public displayedColumns: string[] = ['select', 'fileName', 'fileTime', 'folder', 'owner', 'fileSize', 'view', 'cacheFile'];
  public fileTypes: string[] = ['csv', 'h5', 'txt', 'gz', 'zip'];
  public selectedFileType: string;
  public rawData = [];
  public dataSource = new MatTableDataSource<any>([]);
  public allFiles = [];
  public uploadProgress: any[] = [];
  public uploadComplete: boolean = false;
  public view: string = 'TABLE';
  public screenInfo: any = null;
  public logInfo: any = null;
  public menuPosition = { x: '0px', y: '0px' };
  public selection = new SelectionModel<any>(true, []);
  private teamName: string;
  public projects: Project[] = [];
  public saving = false;
  private _subs = new SubscriptionContainer();
  public isPolling: boolean = false;
  public lastUpdated: Date;
  public terminalId = new Date().getTime().toString();
  private _pollingTimer?: number;
  public selectedDataset: string = null;
  public filteredDatasets: string[] = [];

  public get hasSelected(): boolean {
    return this.selection.selected.length !== 0;
  }


  private _namecat = 30;
  public get namecat() : number {
    return this._namecat;
  }

  private $loadNewRawData(data: any[]) {
    this.rawData = data;
    this.rawData.sort((a, b) => Date.parse(b.fileTime) - Date.parse(a.fileTime));
    this.allFiles = this.rawData;

    if (!this.selectedDataset || this.selectedDataset === this.rawService.allFilesText) {
      this.isLoading = false;
      this.dataSource.data = this.rawData;
      if (this.selection) {
        this.selection.clear();
      }
      this.applyTableSettings();
    }
  }

  private openDialog(mode: 'copy' | 'move', raw: any): Promise<RelocateRawParameters[]> {
    return new Promise((resolve, reject) => {
      try {
        const ref = this.dialog
          .open<MoveRawDialogComponent, MoveRawDialogData, RelocateRawParameters[]>(MoveRawDialogComponent, {
            minWidth: '500px',
            data: { mode, raws: raw, teamName: this.teamName }
          })
          .afterClosed()
          .subscribe((path) => {
            ref.unsubscribe();
            resolve(path);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public rawService: RawService,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private cacheFileService: CacheFileService,
    private datasetsService: DatasetsService,
    private _settings: SettingsService  ) {}

  ngAfterViewInit(): void {
    this._subs.add = this.activatedRoute.params.subscribe((params) => {
      this.load(params.team);
    });

    this._subs.add = this._settings.pollingRate$.subscribe(this.$changePollingRate.bind(this));
    setTimeout(() => this.filterDatasets(null), 3);
  }

  ngOnInit(): void {
    (<any>window).raw = this;
    addEventListener('dragenter', (event) => {
      if (this.uploadService.dialog.openDialogs.length == 0) this.openFileUpload();
    });
    this.$changePollingRate(this._settings.pollingRate);
    this._subs.add = this.datasetsService.pathAdded$.subscribe(async (response) => {
      if (this.selectedDataset !== response.dataset) return;

      const raw = await this.rawService.getAsync(this.teamName);
      this.$loadNewRawData(raw);
      this.selectDataset(response.dataset);

    });
  }

  ngOnDestroy(): void {
    this._subs.dispose();
    if (this._pollingTimer) clearTimeout(this._pollingTimer);
  }

  a = 925;
  b = 5;
  c = 10;
  optimizePageSize() {
    var tableWidth = (<any>this.rawfileTable)._elementRef.nativeElement.clientWidth;
    this._namecat = Math.max(tableWidth - this.a, 0) / this.b + this.c;

    const parent = (<any>document.querySelector('app-raw'));
    if (!parent) return;

    const parentHeight: number = parent.offsetHeight;
    var x = parentHeight;

    this.pageSize = Math.round(0.02 * x) - 4;
    if (this.paginator) {
      this.paginator._changePageSize(this.pageSize);
    }
  }

  filterDatasets(filterValue: string): void {
    if (!filterValue || filterValue.length === 0) {
      this.filteredDatasets = this.allDatasets.slice(1);
      return;
    }

    this.filteredDatasets = this.allDatasets.slice(1).filter((s) => s.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  load(teamName: string) {
    this.teamName = teamName;
    const sub = this.rawService.get(teamName).subscribe((response: any[]) => {
      sub.unsubscribe();
      this.isLoading = false;
      this.lastUpdated = new Date();
      this.$loadNewRawData(response);
    });
  }

  public async refreshCacheData(): Promise<void> {
    this.isPolling = true;
    const rawData = await this.rawService.getAsync(this.teamName);
    const cacheData = Object.fromEntries(
      rawData.map(({ fullPath, hasCacheError, hasCacheFile }) => [fullPath, { hasCacheError, hasCacheFile }])
    );

    for (const rds of this.rawData) {
      if (rds.fullPath in cacheData) Object.assign(rds, cacheData[rds.fullPath]);
    }

    this.lastUpdated = new Date();
    this.isPolling = false;
  }

  selectDataset(name: string) {
    this.isLoading = true;
    this.selection.clear();
    this.selectedDataset = name;

    if (!name || name === this.rawService.allFilesText) {
      this.dataSource.data = this.allFiles;
      this.isLoading = false;
      this.applyTableSettings();
      return;
    }

    const sub = this.datasetsService.get(this.teamName, name).subscribe((results) => {
      sub.unsubscribe();
      if (!results) return;

      const filtered = this.allFiles.filter((x) => results.raw?.includes(x.fullPath));
      this.dataSource.data = filtered;
      this.isLoading = false;
      this.unselectAll();
      this.applyTableSettings();
    });
  }

  applyTableSettings() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.optimizePageSize();
    }, 0);
  }

  async toggleSelection(row) {
    this.selection.toggle(row);
  }

  closeCacheView() {
    this.view = 'TABLE';
  }

  explore() {
    this.router.navigate([this.teamName, 'data', 'explore', this.selectedDataset]);
  }

  refresh() {
    this.isLoading = true;
    this.load(this.teamName);
  }

  unselectAll() {
    this.dataSource.filteredData.forEach((row: any) => {
      if (this.selection.selected.find((x) => x.fullPath == row.fullPath)) this.toggleSelection(row);
    });
  }

  selectAll() {
    this.dataSource.filteredData.forEach((row: any) => {
      if (!this.selection.selected.find((x) => x.fullPath == row.fullPath)) this.toggleSelection(row);
    });
  }

  onFileTypeSelect(event: any) {
    if (!this.selectedFileType) {
      this.dataSource.data = this.rawData;
      return;
    }

    this.dataSource.data = this.rawData.filter((x) => x.fileName.includes(event.value));
  }

  async openFileUpload() {
    const dataset = this.selectedDataset !== null && this.selectedDataset !== this.rawService.allFilesText ? this.selectedDataset : null;
    const results = await this.uploadService.promptForUpload(this.teamName, dataset);
    if (results && results.reload) {
      this.isLoading = true;

      if (!dataset) {
        this.load(this.teamName);
      } else {
        this.selectDataset(dataset);
      }

    }
  }

  async onViewCacheFile($ev: PointerEvent, row) {
    $ev.stopPropagation();

    this.view = 'CACHE';
    this.logInfo = null;
    this.screenInfo = { id: row.cacheFileScreen };
  }

  async onViewCacheLog($ev: PointerEvent, row) {
    $ev.stopPropagation();
    this.view = 'CACHE';
    if (this.screenInfo != null) this.terminal.disconnect();
    this.screenInfo = null;
    const path = `${row.folder}/.adapter/create_cachefile_${row.fileName.split('.')[0]}.${row.fileName.split('.')[1]}.txt`;
    this.logInfo = { path };
  }

  $createCacheClick(items = []) {
    if(items.length == 0) {
      items = this.selection.selected;
    }
    for (const raw of items) {
      if (!raw) continue;

      if (raw.cacheFileScreen) {
        console.warn(`Already caching ${raw.fullPath}.`);
        continue;
      }

      this.cacheFileService.createCacheFileAsync(this.teamName, raw.fullPath);
    }

    setTimeout(() => {
      this.load(this.teamName);
    }, 100);
  }

  $relocateClick(mode: 'move' | 'copy') {
    this.isLoading = true;
    this.openDialog(mode, this.selection.selected).then((response) => {
      if (!response) {
        this.isLoading = false;
        return;
      }

      const sub = this.rawService.relocate(this.teamName, mode, response).subscribe((response) => {
        sub.unsubscribe();
        this.$loadNewRawData(response);
        if (mode === 'move' && this.selectedDataset && this.selectedDataset !== this.rawService.allFilesText) {
          this.selectDataset(this.selectedDataset);
        }
      });
    });
  }

  $deleteClick() {
    if (!confirm('Are you sure you want to delete these raw data files?')) return;

    this.isLoading = true;
    const sub = this.rawService
      .delete(
        this.teamName,
        this.selection.selected.map(({ host: hostName, fullPath: path }) => ({ hostName, path }))
      )
      .subscribe((response) => {
        sub.unsubscribe();
        this.$loadNewRawData(response);
        if (this.selectedDataset && this.selectedDataset !== this.rawService.allFilesText) {
          this.selectDataset(this.selectedDataset);
        }
      });
  }

  $viewClick($event, row) {
    $event.stopPropagation();
    if (!row.hasCacheFile && row.fullPath.includes('.csv')) {
      alert('Please create a cache file before viewing this asset.');
      return;
    }

    if (this.isWidget) {
      let fileType = null;
      if (row.fullPath.endsWith('.csv') || row.fullPath.endsWith('.csv.gz')) {
        fileType = 'csv';
      } else {
        fileType = row.fullPath.split('/').pop().split('.')[1];
      }

      this.onViewData.emit({ path: row.fullPath, fileType });
    } else {
      this.router.navigate([this.teamName, 'data', 'data-viewer', encodeURI(row.fullPath.replace(/\//g, '\\'))]);
    }
  }

  public $changePollingRate(newRate: number): void {
    if (this._pollingTimer) clearInterval(this._pollingTimer);
    this._pollingTimer = setInterval(this.refreshCacheData.bind(this), newRate) as unknown as number;
  }

  public selectedDatasets: string[]  = [];
  async onSelectDataset(value: string) {

    if (this.selectedDatasets.includes(value)) {
      this.selectedDatasets = this.selectedDatasets.filter(x => x !== value);
      return;
    }

    this.selectedDatasets.push(value);
  }

  public async addPathsToDatasets() {

    const files = this.selection.selected;
    const datasets = this.selectedDatasets;

    this.isLoading = true;
    for (const file of files) {
      for (const dataset of datasets) {
        await this.datasetsService.update(this.teamName, dataset, file.fullPath, true);
      }
    }

    this.isLoading = false;
    this.selectedDatasets = [];
    this.dsSelectionList.deselectAll();
  }

  async addDataset() {
    const name = prompt("Enter name for new dataset");
    if (!name || name.trim().length === 0) return;

    const exists = this.allDatasets.find(x => x.toLowerCase() ===  name.toLowerCase());
    if (exists) {
      alert("A dataset with that name already exists.");
      return;
    }

    this.isLoading = true;
    await this.datasetsService.add(this.teamName, name);
    this.allDatasets.push(name);

    const files = this.selection.selected;
    for (const file of files) {
      await this.datasetsService.update(this.teamName, name, file.fullPath, true);
    }
    this.isLoading = false;

    this.filterDatasets(null);
  }

  async onRemoveDataset() {

    this.isLoading = true;
    const paths = this.selection.selected.map(x => x.fullPath);
    const files = this.selection.selected;
    for (const file of files) {
      await this.datasetsService.update(this.teamName, this.selectedDataset, file.fullPath, false);
    }

    const filtered = this.dataSource.data.filter(x => !paths.includes(x.fullPath));
    this.dataSource.data = filtered;
    this.isLoading = false;
  }

  onDatasetMenuClosed() {
    this.selectedDatasets = [];
    this.filterDatasets(null);
    this.dsSelectionList.deselectAll();
    this.datasetSearchInput.nativeElement.value = '';
  }

}


