// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.sparklines-container {
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 0%;
  overflow: hidden;
}

.header-bar {
  padding: 0.75rem;
  display: flex;
  z-index: 10;
}
.header-bar .section {
  display: flex;
  align-items: center;
}
.header-bar .section.left {
  overflow: hidden;
}

.sparklines-container {
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
}

.table-container {
  flex: 1 1 auto;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.hidden {
  display: none;
}

.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Adjust based on theme */
}

.mat-mdc-form-field-bottom-align {
  height: 0;
  margin: 0;
  padding: 0;
}

:host ::ng-deep .mat-mdc-form-field-bottom-align {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/sparklines-widget/sparklines-widget.component.scss"],"names":[],"mappings":"AAEA;EACE,mJACE;AAFJ;;AAeA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;AAZF;;AAeA;EACE,YAAA;EACA,gBAAA;AAZF;;AAeA;EACE,gBAAA;EACA,aAAA;EACA,WAAA;AAZF;AAcE;EACE,aAAA;EACA,mBAAA;AAZJ;AAcI;EACE,gBAAA;AAZN;;AAkBA;EACE,yBAAA;EACA,aAAA;EACA,sBAAA;AAfF;;AAkBA;EACE,cAAA;EACA,gBAAA,EAAA,+BAAA;EACA,kBAAA,EAAA,kCAAA;AAfF;;AAkBA;EACE,aAAA;AAfF;;AAkBA;EACE,eAAA;AAfF;AAgBE;EACE,oCAAA,EAAA,0BAAA;AAdJ;;AAkBA;EACE,SAAA;EACA,SAAA;EACA,UAAA;AAfF;;AAmBE;EACE,wBAAA;AAhBJ","sourcesContent":["@import '../../../../styles/breakpoints';\n\n:host {\n  font-family:\n    system-ui,\n    -apple-system,\n    BlinkMacSystemFont,\n    'Segoe UI',\n    Roboto,\n    Oxygen,\n    Ubuntu,\n    Cantarell,\n    'Open Sans',\n    'Helvetica Neue',\n    sans-serif;\n}\n\n.sparklines-container {\n  height: calc(100% - 24px);\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  flex: 1 1 0%;\n  overflow: hidden;\n}\n\n.header-bar {\n  padding: 0.75rem;\n  display: flex;\n  z-index: 10;\n\n  .section {\n    display: flex;\n    align-items: center;\n\n    &.left {\n      overflow: hidden;\n    }\n  }\n\n}\n\n.sparklines-container {\n  height: calc(100% - 24px);\n  display: flex;\n  flex-direction: column;\n}\n\n.table-container {\n  flex: 1 1 auto;\n  overflow-y: auto; /* Enables vertical scrolling */\n  overflow-x: hidden; /* Prevents horizontal scrolling */\n}\n\n.hidden {\n  display: none;\n}\n\n.clickable-row {\n  cursor: pointer;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.1); /* Adjust based on theme */\n  }\n}\n\n.mat-mdc-form-field-bottom-align {\n  height: 0;\n  margin: 0;\n  padding: 0;\n}\n\n:host {\n  ::ng-deep .mat-mdc-form-field-bottom-align {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
