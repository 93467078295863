import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgTerminal } from 'ng-terminal';
import { AuthGuard } from 'src/app/services/auth.guard';
import { IdentityService } from 'src/app/services/identity.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-terminal',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('child', { static: false }) child: NgTerminal;
  @Input() command: string = null;
  @Input() readOnly: boolean = false;
  @Input() teamName: string;
  @Input() disconnectEvent: boolean = false;
  @Input() isWidget: boolean = false;
  @Input() terminalId: string = null;
  @Input() disconnectOnDestroy: boolean = false;

  @Output() disconnected = new EventEmitter();

  private subscription: any;
  private commandExecuted: boolean = false;

  public isLoading: boolean = true;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.disconnect();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private socketService: SocketService,
    private identity: IdentityService,
    private authGuard: AuthGuard
  ) {}

  ngOnInit(): void {
    (<any>window).terminal = this;
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', () => this.onResize());
    if (this.disconnectOnDestroy) this.disconnect();
  }

  onResize() {
    this.socketService.emit('resize', this.getScreen());
  }

  ngAfterViewInit(): void {
    this.child.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      fontSize: 13,
      cursorBlink: true
    });

    window.addEventListener('resize', () => this.onResize());

    if (this.command || this.isWidget) {
      this.load(this.teamName);
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.load(params.team);
      });
    }
  }

  public async load(teamName: string): Promise<void> {
    this.teamName = teamName;

    this.isLoading = false;
    if (!this.readOnly) this.subscribeToTerminal();

    setTimeout(() => {
      this.connect();
      this.child.underlying.focus();
    }, 100); // bugfix: getScreen will return NaN if the view hasn't rendered completely
  }

  public subscribeToTerminal(): void {
    this.child.onKey().subscribe(({ key }) => {
      this.socketService.emit('ssh', { terminalId: this.terminalId, msg: key });
    });
  }

  public subscribeToSocketEvents(config: any): void {
    this.socketService.sshConnect(config);
    this.subscription = this.socketService.sshEvents.subscribe((response: any) => {
      if (response.terminalId !== this.terminalId) return;

      let msg = response.msg;
      this.child.write(msg);
      if ((this.disconnectEvent || this.isWidget) && msg.trim() === '*** SSH CONNECTION CLOSED ***') this.disconnect();
      if (this.command && !this.commandExecuted && msg.endsWith('$ ')) {
        this.commandExecuted = true;
        this.socketService.emit('ssh', { terminalId: this.terminalId, msg: this.command + '\r' });
      }
    });
  }

  public disconnect(): void {
    if (!this.subscription) return;

    this.socketService.sshDisconnect();
    this.subscription.unsubscribe();
    this.subscription = null;
    if (this.disconnectEvent) this.child.underlying.dispose();
    else this.child.underlying.reset();
    this.disconnected.emit();
  }

  public async connect(): Promise<void> {
    const token = localStorage.getItem('id_token');
    if (!token) {
      this.authGuard.logout();
      return;
    }

    const socketConfig = { teamName: this.teamName, username: this.identity.me.username, token: token, terminalId: this.terminalId };
    const screenInfo = this.getScreen();
    socketConfig['screen'] = screenInfo;
    this.subscribeToSocketEvents(socketConfig);
  }

  public getScreen(): { cols: number; rows: number } {
    const terminalElement = document.querySelector('ng-terminal');
    if (!terminalElement) {
      return { cols: 80, rows: 24 }; // Default values if element is not found
    }

    const width = terminalElement.clientWidth;
    const height = terminalElement.clientHeight;

    // Create a hidden element to measure character dimensions
    let measureElement = document.getElementById('measure-element');
    if (!measureElement) {
      measureElement = document.createElement('div');
      measureElement.id = 'measure-element';
      measureElement.style.visibility = 'hidden';
      measureElement.style.position = 'absolute';
      measureElement.style.whiteSpace = 'pre'; // Ensure no wrapping
      measureElement.textContent = 'M'; // Use a wide character like 'M' for measurement
      document.body.appendChild(measureElement);
    }

    // Apply the same font settings as your terminal
    const fontFamily = '"Cascadia Code", Menlo, monospace';
    const fontSize = 13;
    measureElement.style.fontFamily = fontFamily;
    measureElement.style.fontSize = `${fontSize}px`;

    // Measure the dimensions of a single character
    const charWidth = measureElement.clientWidth;
    const charHeight = measureElement.clientHeight;

    const cols = Math.floor(width / charWidth);
    const rows = Math.floor(height / charHeight);

    return { cols, rows };
  }

}
