import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { TerminalComponent } from '../../terminal/terminal.component';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-terminal-widget',
  templateUrl: './terminal-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './terminal-widget.component.scss']
})
export class TerminalWidgetComponent extends BaseWidgetComponent implements OnInit {
  @ViewChild('terminal') terminal!: TerminalComponent;

  @Input() command: string = null;
  @Input() title: string = null;
  public readOnly: boolean = false;

  public terminalId = new Date().getTime().toString();
  constructor(private _widgetService:WidgetService) {
    super();
  }

  ngOnInit(): void {
    if (this.command) this.readOnly = true;
    this._subs.add = this._widgetService.resize$.subscribe((response) => {
      this.terminal.onResize();
    });
  }

  onDisconnect() {
    super.close();
  }


  close() {
    this.terminal.disconnect();
    super.close();
  }
}
