// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .sparkline-container {
        width: 100%;
        height: 50px;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/sparklines-widget/sparkline-chart.component.ts"],"names":[],"mappings":";MACM;QACE,WAAW;QACX,YAAY;MACd","sourcesContent":["\n      .sparkline-container {\n        width: 100%;\n        height: 50px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
